import {
  getRealties,
  getSoldAmount,
  getFbPosts,
  checkAuth,
  updateData,
} from './action.general'

export const actions = {
  getRealties,
  getSoldAmount,
  getFbPosts,
  checkAuth,
  updateData,
}
