import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
// import { FacebookProvider, EmbeddedPost } from 'react-facebook'
// import { isMobile } from 'react-device-detect'
import './FbFeed.scss'

import { useSelector } from 'react-redux'

const FbFeed = () => {
  const location = useLocation()
  const loading = useSelector((state) => state.general.loading)
  const [fbElement, setFbElement] = useState(<div />)
  const [device, setDevice] = useState('mobile')
  const [width, setWidth] = useState(0)
  const [hideFeed, setHideFeed] = useState(true)

  // const fbPosts = useSelector((state) => state.general.fbPosts)
  // const [postElements, setPostElements] = useState(undefined)

  useEffect(() => {
    if (loading) {
      setHideFeed(true)
    } else if (!loading && location.pathname === '') {
      setHideFeed(false)
    } else if (!loading && location.pathname === '/') {
      setHideFeed(false)
    } else {
      setHideFeed(true)
    }
  }, [location.pathname, loading])

  useEffect(() => {
    setWidth(window.innerWidth)
    // eslint-disable-next-line
  }, [window.innerWidth])

  useEffect(() => {
    if (width > 1024 && device === 'mobile') {
      setDevice('desktop')
    }
    if (width < 1025 && device === 'desktop') {
      setDevice('mobile')
    }
    // eslint-disable-next-line
  }, [width])

  useEffect(() => {
    if (device === 'mobile') {
      setFbElement(
        <div
          className="fb-page"
          data-href="https://www.facebook.com/laatulkv"
          data-tabs="timeline"
          // data-width=""
          data-height="500"
          data-small-header="true"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite="https://www.facebook.com/laatulkv"
            className="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/laatulkv">Laatu LKV</a>
          </blockquote>
        </div>
      )
    } else {
      setFbElement(
        <div
          className="fb-page"
          data-href="https://www.facebook.com/laatulkv"
          data-tabs="timeline"
          data-width="500"
          data-height="750"
          data-small-header="true"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite="https://www.facebook.com/laatulkv"
            className="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/laatulkv">Laatu LKV</a>
          </blockquote>
        </div>
      )
    }
  }, [device])

  // useEffect(() => {
  //   if (fbPosts) {
  //     const elements = fbPosts.map((el) => (
  //       <EmbeddedPost key={el} href={el} target="_top" width="350" />
  //     ))

  //     const fbPlugin = (
  //       <FacebookProvider appId="758676015023358">{elements}</FacebookProvider>
  //     )

  //     setPostElements(fbPlugin)
  //   }
  // }, [fbPosts])

  useEffect(() => {
    if (window.FB && !loading) {
      window.FB.XFBML.parse()
    }
  }, [
    // postElements,
    // setPostElements,
    // fbPosts,
    loading,
    location.pathname,
    fbElement,
  ])

  return (
    <div className="FbFeed" hidden={hideFeed}>
      <div className="FbColText">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/"
          className="linkTitle"
        >
          <h2 className="customSubTitle">Laatu LKV Facebookissa</h2>
        </a>
      </div>
      {/* <div className={`FbColPosts ${isMobile ? 'scaleDown' : ''}`}>
        {postElements ? postElements : null}
      </div> */}
      <div className="FbFeedContainer">{fbElement}</div>
    </div>
  )
}

export default FbFeed
