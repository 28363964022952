import { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Button } from 'react-bootstrap'
import axios from 'axios'
import logo from '../../images/laatulkv_logo_transparent.png'
import iconPhone from '../../images/phone-alt-solid.svg'
import iconEmail from '../../images/at-solid.svg'
import hostname from '../../hostname'
import '../Koti/Koti.scss'

const Kuvat = () => {
  const { id } = useParams()
  const [item, setItem] = useState(undefined)
  const [realtyElement, setRealtyElement] = useState(<div />)
  const [realtyAgent, setRealtyAgent] = useState(null)
  const [imageSlides, setImageSlides] = useState([])

  async function getData() {
    try {
      const res = await axios.get(`${hostname}/api/realties-for-sale`)
      let realties = res.data
      let idArray = realties.map((el) => el.identifier)
      let i = idArray.indexOf(parseInt(id))
      let realty = realties[i]

      if (realty) {
        setItem(realty)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if (item) {
      const agentElement = (
        <div>
          <img src={item.realtor.avatar} alt={`${item.realtor.name}`} className='agent-image' />
          <div className='realty-agent'>
            <div className='agent-name'>
              <h6>
                {item.realtor.name}
              </h6>
              <p>{item.realtor.jobTitle}</p>
            </div>
            <img src={logo} alt='Laatu LKV' height='58' />
            <div className='agent-contact'>
              <div>
                <img src={iconPhone} alt='Puhelin' />
                {item.realtor.tel}
              </div>
              <div>
                <img src={iconEmail} alt='Sähköpostiosoite' />
                <a href={`mailto:${item.realtor.email}`}>{item.realtor.email}</a>
              </div>
            </div>
          </div>
        </div>
      )
      setRealtyAgent(agentElement)
    }
  }, [item])

  useEffect(() => {
    if (item && item.images) {
      // const mainImage = item.images.filter((el) => el.TYPE === 'MAIN')
      // const floorPlanImage = item.images.filter((el) => el.TYPE === 'FLOOR_PLAN')
      // const generalImages = item.images.filter((el) => el.TYPE === 'GENERAL')
      // if (floorPlanImage.length > 0) generalImages.push(floorPlanImage[0])
      // generalImages.sort((a, b) => a.SEQ - b.SEQ)
      // if (mainImage) generalImages.unshift(mainImage[0])
      const imageElements = item.images.map((el, index) => (
        <div className='imageListElement' key={index}>
          <img src={el.compressed} alt={el.description} />
          <p>{el.description}</p>
        </div>
      ))
      setImageSlides(imageElements)
    }
  }, [item])

  useEffect(() => {
    if (item && imageSlides.length > 0) {
      const itemElement = (
        <div className='single-realty'>
          {imageSlides}
          <div className='text-information-block mobile-agent'>
            <h3>KOHDETTA VÄLITTÄÄ</h3>
            <img src={item.realtor.avatar} alt={`${item.realtor.name}`} />
            <div className='mobile-agent-container'>
              <div className='mobile-agent-col-1'>
                <h6>
                  <strong>
                    {item.realtor.name}
                  </strong>
                </h6>
                <p>{item.realtor.jobTitle}</p>
              </div>
              <div className='mobile-agent-col-2'>
                <div className='mobile-agent-col-3'>
                  <img src={iconPhone} alt='Puhelin' id='mobile-agent-icon' />
                  <p>{item.realtor.tel}</p>
                </div>
                <div className='mobile-agent-col-3'>
                  <img src={iconEmail} alt='Sähköpostiosoite' id='mobile-agent-icon' />
                  <a href={`mailto:${item.realtor.email}`}>{item.realtor.email}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )

      setRealtyElement(itemElement)
    }
    // eslint-disable-next-line
  }, [item, imageSlides])

  return (
    <div className='Koti animate__animated animate__fadeIn'>
      <Helmet>
        {item ? <title>{item.address}, {item.districtFree}, {item.city} | Laatu LKV</title> : null}
      </Helmet>
      <div className='container-realty no-side-padding'>
        <Link to={`/koti/${id}`} style={{ color: '#000000', textDecoration: 'none' }}>
          <Button variant='laatulkv'>Takaisin kodin tietoihin</Button>
        </Link>
        {realtyElement}
      </div>
      {realtyAgent}
    </div>
  )
}

export default Kuvat
