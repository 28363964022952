import { useEffect, useState } from 'react'
import './Info.scss'

const Info = (props) => {
  const [textOne, setTextOne] = useState(undefined)
  const [textTwo, setTextTwo] = useState(undefined)
  const [value, setValue] = useState(undefined)

  useEffect(() => {
    setTextOne(props.textOne)
    setTextTwo(props.textTwo)
    setValue(props.value)
    // eslint-disable-next-line
  }, [])

  return (
    <div className="Info">
      <p>
        <span>{textOne}</span>
        <span style={{ margin: '0 10px', fontSize: '24px' }}>{value}</span>
        <span>{textTwo}</span>
      </p>
    </div>
  )
}

export default Info
