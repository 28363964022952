import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import iconPhone from '../../images/phone-alt-solid.svg'
import iconEmail from '../../images/at-solid.svg'
import piia from '../../images/piia-heikkilä-kuva.jpg'
import mika from '../../images/mika-lehtonen-kuva.jpg'
import kaisa from '../../images/kaisa-kauppi-kuva.jpg'
import './Laatulkv.scss'

const Laatulkv = () => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (location.hash === '#piia') {
      const element = document.getElementById('Piia')
      const newYcoord = element.offsetTop - 90
      window.scrollTo(0, newYcoord)
    }
    if (location.hash === '#mika') {
      const element = document.getElementById('Mika')
      const newYcoord = element.offsetTop - 90
      window.scrollTo(0, newYcoord)
    }
  }, [location.hash])

  return (
    <div className='Laatulkv animate__animated animate__fadeIn'>
      <Helmet>
        <title>Laatu LKV | Esittely | Kiinteistönvälitys Hämeenlinna. Kiinteä ja edullinen välityspalkkio. Hyvä ja kokenut kiinteistönvälittäjä. Asunnon myynti kodistasi välittäen.</title>
      </Helmet>
      <h1
        className='customSubTitle'
        style={{ backgroundColor: '#fecbb8', padding: '16px 24px 12px 8px' }}>
        Laatu LKV
      </h1>

      <div className='personContainer' id='Piia'>
        <div className='personHeader'>
          <div className='headerSlot'>
            <h2 className='customSubTitle'>Piia Heikkilä</h2>
            <h3>Yrittäjä, Kiinteistönvälittäjä LKV, Kaupanvahvistaja</h3>
            <div className='henkiloYhteystiedot'>
              <div>
                <img src={iconPhone} alt='Puhelin' />
                050 452 5254
              </div>
              <div>
                <img src={iconEmail} alt='Sähköpostiosoite' />
                <a href='mailto:piia@laatulkv.fi'>piia@laatulkv.fi</a>
              </div>
            </div>
          </div>
          <div className='headerSlot'>
            <img src={piia} alt='Piia Heikkilä' />
          </div>
        </div>
        <p>
          Olen Piia Heikkilä, kolmekymppinen hämeenlinnalainen myynnin ja kiinteistönvälityksen
          ammattilainen.
        </p>
        <p>
          Olen tehnyt urani erilaisissa asiakaspalvelu- ja myyntitehtävissä, mistä reilu 12 vuotta
          kiinteistönvälitysalalla. Aloitin kiinteistönvälitysurani Hämeenlinnan
          Huoneistokeskuksessa vuoden 2010 alussa ja LKV-tutkinnon suoritin marraskuussa 2011 ja
          kaupanvahvistajana olen toiminut vuodesta 2020.
        </p>
        <p>
          Työskentelin Huoneistokeskuksessa, kunnes muutimme pääkaupunkiseudulle syksyllä 2013.
          Tuolloin siirryin NCC Asuminen Oy:lle (nykyinen Bonava Suomi Oy ) uudisasuntokaupan
          pariin, jossa työskentelin yli kolme vuotta. Muutimme takaisin Hämeenlinnaan kesällä 2017,
          jolloin oli loistava aika toteuttaa unelmani omasta yrityksestä, Laatu LKV:stä.
        </p>
        <p>
          Perheeseeni kuuluu puolisoni lisäksi kaksi koiraamme Pulla ja Pontus. Harrastuksiini
          kuuluu crossfitissa käynti sekä lenkkeily koirien kanssa. Talvella tulee myös harrastettua
          hiihtoa ja muita aktiviteetteja.
        </p>
        <p>
          Olen luotettava, helposti lähestyttävä sekä periksiantamaton. Kiinteistönvälitys ja
          asiakaspalvelu ovat intohimojani: teen kotisi myynnistä sinulle mahdollisimman helppoa ja
          mukavaa, asiakaslähtöisesti ja suurella sydämellä.
        </p>
        <h3 className='personFeedbackTitle'>Piian asiakaspalautteita</h3>
        <ul>
          <li><p>
            "Erittäin tyytyväinen toimintaan! Koko ajan tiesimme missä mennään, jatkuva yhteydenpito tilanteesta. Loppujen lopuksi kaupat tehtiin nopeasti ja kaikkia kuunnellen. Palvelu muutenkin mukavaa ja ihmisläheistä, kuten kuuluukin. Tuntui koko ajan, että homma ei lipsu lapasesta vaan juna puksuttaa eteenpäin, kuten kuuluukin."
          </p></li>
          <li><p>
            "Palvelu oli ammattimaista ja asiantuntevaa. Välittäjä oli selvästi perehtynyt kohteeseen. Kaikki tekniset tiedot ja pienet yksityiskohdat heti tiedossa ja "muistissa"."
          </p></li>
          <li><p>
            "Perusteellisuus, jolla asiat hoidettiin kaikissa vaiheissa. Asiantuntijuus vakuutti, miellyttävä ja rauhallinen tapa neuvotella. Koko prosessi herätti luottamusta."
          </p></li>
        </ul>
      </div>

      <div className='personContainer' id='Mika'>
        <div className='personHeader'>
          <div className='headerSlot'>
            <h2 className='customSubTitle'>Mika Lehtonen</h2>
            <h3>Kiinteistönvälittäjä LKV, Kaupanvahvistaja</h3>
            <div className='henkiloYhteystiedot'>
              <div>
                <img src={iconPhone} alt='Puhelin' />
                050 476 2332
              </div>
              <div>
                <img src={iconEmail} alt='Sähköpostiosoite' />
                <a href='mailto:mika@laatulkv.fi'>mika@laatulkv.fi</a>
              </div>
            </div>
          </div>
          <div className='headerSlot'>
            <img src={mika} alt='Mika Lehtonen' />
          </div>
        </div>
        <p>
          Olen Mika Lehtonen, viisikymppinen ”suur-hattulalainen” perheenisä ja
          kiinteistönvälityksen konkari.
        </p>
        <p>
          Rakensimme vaimoni kanssa talon hänen synnyinseudulle Hattulaan maaseudun rauhaan vuonna
          2004, jossa olemme erittäin hyvin viihtyneet. Perheeseeni kuuluu vaimoni lisäksi kaksi
          tytärtä. Olen kotoisin Janakkalasta ja onpa tullut Hämeenlinnankin puolella asuttua reilu
          10 vuotta. Näiden lisäksi yli 20 alalla viettämääni vuotta takaavat parhaan mahdollisen
          paikallistuntemuksen ja tietämyksen alueista sekä kohteista.
        </p>
        <p>
          Harrastuksiini kuuluu kaikenlainen liikkuminen, kalastus sekä perheen kanssa yhdessä
          toimiminen. Perheen yhteisiin harrastuksiin kuuluu myös HPK:n peleissä käyminen.
        </p>
        <p>
          Työskentelin ennen Laatu LKV:lle siirtymistä Hämeenlinnan Huoneistokeskuksessa 20 vuotta,
          LKV-kokeen suoritin vuonna 2001 ja kaupanvahvistajana olen toiminut vuodesta 2020. Olen
          helposti lähestyttävä ja luotettava, asiantunteva välitystapani takaa tehokkuuden ja hyvät
          tulokset: kotisi ansaitsee tulla välitetyksi aidolla ammattitaidolla.
        </p>
        <h3 className='personFeedbackTitle'>Mikan asiakaspalautteita</h3>
        <ul>
          <li><p>
            "Kaikki hoitui ensimmäisestä puhelinsoitosta alkaen aivan käsittämättömän hyvin. Tiesimme koko ajan miten prosessi etenee ja lopulta myös saimme asunnosta toivomamme hinnan. Asunnon välitystä ei olisi paremmin voinut hoitaa."
          </p></li>
          <li><p>
            "Olin aiemmin monia vuosia sitten asioinut Lehtosen kanssa. Olin silloin ostopuolella, mutta muistissa oli asiantunteva ja asiakkaita kunnioittava palvelu. Kilpailutustilanteessa teidän välitysliike ja välittäjä vakuutti eniten. Myös ensimmäiseen tapaamiseen oli valmistauduttu kilpailijoita paremmin."
          </p></li>
          <li><p>
            "Näin ensiasunnon ostajana moni asia oli hyvin epäselvää. Mika kuitenkin vastasi kaikkiin kysymyksiin ja neuvoi koko prosessin läpi ja ylipäänsä kertoi, miten asiat tyypillisesti etenevät. Lisäksi en missään vaiheessa kokenut painostusta tai kiireen tuntua, joten tuntui, että tässä aidosti annetaan aikaa eikä yritetä pakkomyydä."
          </p></li>
        </ul>
      </div>

      <div className='personContainer' id='Kaisa'>
        <div className='personHeader'>
          <div className='headerSlot'>
            <h2 className='customSubTitle'>Kaisa Kauppi</h2>
            <h3>Sisustusstailaaja, Myyntiassistentti, Markkinointivastaava</h3>
            <div className='henkiloYhteystiedot'>
              <div>
                <img src={iconPhone} alt='Puhelin' />
                050 328 8889
              </div>
              <div>
                <img src={iconEmail} alt='Sähköpostiosoite' />
                <a href='mailto:kaisa@laatulkv.fi'>kaisa@laatulkv.fi</a>
              </div>
            </div>
          </div>
          <div className='headerSlot'>
            <img src={kaisa} alt='Kaisa Kauppi' />
          </div>
        </div>
        <p>
          Olen Kaisa Kauppi, kolmekymppinen ja kotoisin Hämeenlinnasta, mutta muuttanut jokin aika
          sitten Tampereelle.
        </p>
        <p>
          Laatu LKV:llä työnkuvaani kuuluu monipuolisesti hallinnon ja markkinoinnin tehtävät sekä
          asuntojen myyntistailaukset.
        </p>
        <p>
          Työskentelin aikaisemmin henkilöstöalalla sekä rakennusalalla hallinnossa asiakaspalvelun
          ja markkinoinnin parissa. Koulutukseltani olen myynnin ja markkinoinnin merkonomi sekä
          sisustusstailaaja.
        </p>
        <p>Harrastuksiini kuuluu sisustaminen, musiikki sekä keramiikka ja käsityöt.</p>
      </div>
    </div>
  )
}

export default Laatulkv
