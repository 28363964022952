import './EsittelyVideo.scss'
import { useSelector } from 'react-redux'

const EsittelyVideo = () => {
  const loading = useSelector((state) => state.general.loading)

  return (
    <div className="EsittelyVideo" hidden={loading}>
      <div className="EsittelyVideoColText">
        <h2 className="customSubTitle">Esittelyvideo</h2>
        <iframe
          title="Laatu LKV, esittelyvideo"
          width="800"
          height="450"
          src="https://www.youtube.com/embed/gvIuI8vir10"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

export default EsittelyVideo
