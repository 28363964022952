import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import './Yhteistyossa.scss'

import fix2fit from '../../images/yhteistyokumppanit/fix2fit-logo.png'
import laatumuutot from '../../images/yhteistyokumppanit/Hämeen-Laatumuutot-logo-XS.png'
import salonviolet from '../../images/yhteistyokumppanit/Salon-Violet-musta.jpg'
import kalustetukku from '../../images/yhteistyokumppanit/Kalustetukku_logo.png'
import kaunista from '../../images/yhteistyokumppanit/Kaunista_pintaa_LOGO.png'
import koti from '../../images/yhteistyokumppanit/Koti_Consulting_logo_ympyralla_4v.png'
import etelakasi from '../../images/yhteistyokumppanit/Logo-Etelä8_musta_v.png'
import pop from '../../images/yhteistyokumppanit/POP_Pankki_Lammi.png'
import jarki from '../../images/yhteistyokumppanit/Jarki-saneeraus-logo.png'
import wilcode from '../../images/yhteistyokumppanit/wilcode-logo-pink-360x360.png'
import crossfitIlves from '../../images/yhteistyokumppanit/crossfit_ilves.png'
import legion from '../../images/yhteistyokumppanit/Legion_HML_logo_1500x1500_musta.png'
import ritariKlinikka from '../../images/yhteistyokumppanit/RitariKlinikka-Rabbit-1.png'

import hpkLogo from '../../images/yhteistyokumppanit/hpk-logo.png'
import aahLogo from '../../images/yhteistyokumppanit/AAH-logo.png'

const Yhteistyossa = () => {
  const [logoElements, setLogoElements] = useState(undefined)
  const [sportElements, setSportElements] = useState(undefined)
  const list = [
    { img: pop, url: 'https://www.poppankki.fi/lammin-osuuspankki', alt: 'POP Pankki - Lammin Osuuspankki' },
    { img: etelakasi, url: 'https://www.xn--etel8-jra.fi/', alt: 'Etelä8 Lifestyle' },
    { img: kaunista, url: 'https://kaunistapintaa.fi/', alt: 'Kaunista pintaa - Eeva Kajan Tmi' },
    { img: laatumuutot, url: 'http://www.laatumuutot.fi/', alt: 'Hämeen Laatumuutot' },
    { img: koti, url: 'http://www.koticonsulting.fi/', alt: 'Koti Consulting' },
    { img: fix2fit, url: 'https://www.fix2fit.fi/', alt: 'Fix2Fit - Hoidetaan kehosi kuntoon, www.fix2fit.fi' },
    { img: kalustetukku, url: 'https://www.kalustetukku.fi/hameenlinna/', alt: 'Kaluste tukku' },
    { img: salonviolet, url: 'https://www.salonviolet.fi/', alt: 'Salon Violet - Hair & Beauty' },
    { img: jarki, url: 'https://www.jarkikatto.fi/', alt: 'Järki saneeraus' },
    { img: wilcode, url: 'https://wilcode.fi', alt: 'Wilcode' },
    { img: crossfitIlves, url: 'http://www.crossfitilves.com/', alt: 'Crossfit ilves - Toiminnallisen liikkumisen sali' },
    { img: legion, url: 'https://www.legiongym.fi/hameenlinna', alt: 'Legion Gym Hämeenlinna' },
    { img: ritariKlinikka, url: 'https://ritariklinikka.fi/', alt: 'Ritari Klinikka' },
  ]
  const sportList = [
    { img: hpkLogo, url: 'https://hpk.fi', alt: 'HPK' },
    { img: aahLogo, url: 'https://www.aah-hml.fi/', alt: 'AAH Hämeenlinna' },
  ]

  useEffect(() => {
    window.scrollTo(0, 0)

    const elements = list.map((el) => (
      <div className='yhteistyoLogo'>
        <a href={el.url} target='_blank' rel='noopener noreferrer'>
          <img src={el.img} alt={el.alt} />
        </a>
      </div>
    ))
    setLogoElements(elements)

    const sportElements = sportList.map((el) => (
      <div className='yhteistyoLogo'>
        <a href={el.url} target='_blank' rel='noopener noreferrer'>
          <img src={el.img} alt={el.alt} />
        </a>
      </div>
    ))
    setSportElements(sportElements)
    // eslint-disable-next-line
  }, [])

  return (
    <div className='Yhteistyossa animate__animated animate__fadeIn'>
      <Helmet>
        <title>Laatu LKV | Yhteistyössä | Kiinteistönvälitys Hämeenlinna. Kiinteä ja edullinen välityspalkkio. Hyvä ja kokenut kiinteistönvälittäjä. Asunnon myynti kodistasi välittäen.</title>
      </Helmet>

      <h1
        className='customSubTitle'
        style={{ backgroundColor: '#fecbb8', padding: '16px 16px 12px 12px' }}>
        Yhteistyössä
      </h1>
      <p>
        Olemme verkostoituneet usean paikallisen pienyrittäjän kanssa tarjotaksemme asiakkaillemme
        etuhintaisia palveluilta asunnon ja paikkakunnan vaihtoon liittyviin palveluihin.
      </p>
      <div className='yhteistyossaLogoContainer'>{logoElements ? logoElements : null}</div>
      <p>Olemme mukana tukemassa myös paikallista urheilutoimintaa.</p>
      <div className='yhteistyossaLogoContainer'>{sportElements ? sportElements : null}</div>
    </div>
  )
}

export default Yhteistyossa
