import { useEffect, useState } from 'react'
import { useParams, Link, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Button, Carousel } from 'react-bootstrap'
import axios from 'axios'
import dayjs from 'dayjs'
import logo from '../../images/laatulkv_logo_transparent.png'
import iconPhone from '../../images/phone-alt-solid.svg'
import iconEmail from '../../images/at-solid.svg'
import hostname from '../../hostname'
import './Koti.scss'
import EmbedVideo from '../../components/EmbedVideo/EmbedVideo'

const Koti = () => {
  const { id } = useParams()
  const history = useHistory()
  const [item, setItem] = useState(undefined)
  const [realtyElement, setRealtyElement] = useState(<div />)
  const [realtyAgent, setRealtyAgent] = useState(null)
  const [imageSlides, setImageSlides] = useState([])
  const [imageCount, setImageCount] = useState(0)
  // const tuhatErotin = new Intl.NumberFormat()

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  function scrollToVideo() {
    let element = document.getElementById('embed-video-block')
    let topBarHeight = 90
    let destination = element.offsetTop - topBarHeight

    window.scrollTo({
      top: destination,
      behavior: 'smooth'
    })
  }

  // const popover = (
  //   <Popover id='popover-basic'>
  //     <Popover.Content>
  //       <p style={{ margin: 'auto', textAlign: 'center' }}>
  //         Ilmoitettu neliöhinta lasketaan automaattisesti velattomasta lähtöhinnasta.
  //       </p>
  //     </Popover.Content>
  //   </Popover>
  // )

  async function getData() {
    try {
      const res = await axios.get(`${hostname}/api/realties-for-sale`)
      let realties = res.data
      let idArray = realties.map((el) => el.identifier)
      let i = idArray.indexOf(parseInt(id))
      let realty = realties[i]

      if (realty) {
        // console.log(realty)
        setItem(realty)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if (item) {
      const agentElement = (
        <div>
          <div className='agent-image'>
            <img src={item.realtor.avatar} alt={`${item.realtor.name}`} />
          </div>
          <div className='realty-agent'>
            <div className='agent-name'>
              <h6>
                {item.realtor.name}
              </h6>
              <p>{item.realtor.jobTitle}</p>
            </div>
            <img src={logo} alt='Laatu LKV' height='58' />
            <div className='agent-contact'>
              <div>
                <img src={iconPhone} alt='Puhelin' />
                {item.realtor.tel}
              </div>
              <div>
                <img src={iconEmail} alt='Sähköpostiosoite' />
                <a href={`mailto:${item.realtor.email}`}>{item.realtor.email}</a>
              </div>
            </div>
          </div>
        </div>
      )
      setRealtyAgent(agentElement)
    }
  }, [item])

  useEffect(() => {
    if (item && item.images) {
      // const mainImage = item.images.filter((el) => el.TYPE === 'MAIN')
      // const floorPlanImage = item.images.filter((el) => el.TYPE === 'FLOOR_PLAN')
      // const generalImages = item.images.filter((el) => el.TYPE === 'GENERAL')
      // if (floorPlanImage.length > 0) generalImages.push(floorPlanImage[0])
      // generalImages.sort((a, b) => a.SEQ - b.SEQ)
      // if (mainImage) generalImages.unshift(mainImage[0])
      const imagesInCarousel = item.images.length
      const imageElements = item.images.map((el, index) => (
        <Carousel.Item key={index}>
          <img src={el.compressed} alt={el.description} />
          <Carousel.Caption>
            <p>Kuva {index+1}/{imagesInCarousel}{el.description ? ':' : ''} {el.description}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))
      setImageCount(item.images.length)
      setImageSlides(imageElements)
    }
  }, [item])

  useEffect(() => {
    if (item && imageSlides.length > 0) {
      const floorPlan = item.floorPlans[0]
      const videoUrl = item.videoUrl

      const itemElement = (
        <div className='single-realty'>
          <Carousel controls interval={null} indicators={false}>
            {imageSlides}
          </Carousel>
          <Link to={`/kuvat/${id}`} style={{ color: '#000000', textDecoration: 'none' }}>
            <Button variant='laatulkv' style={{ margin: '5px 10px' }}>Katso kaikki kuvat ({imageCount})</Button>
          </Link>
          {videoUrl ? 
            <Button variant='laatulkv' onClick={scrollToVideo} style={{ margin: '5px 10px' }}>Katso esittelyvideo</Button>
          : null}
          <div className='text-title-row'>
            <h2>
              {capitalizeFirstLetter(item.listingType)} | {item.typeOfApartment}
            </h2>
            <h1>
              {item.address}, {item.districtFree}, {item.city}
            </h1>
          </div>
          <div className='text-info-row'>
            <div className='info-box'>
              <h4>HINTA</h4>
              <p>{item.debtFreePrice}</p>
            </div>
            <div className='info-box'>
              <h4>KOKO</h4>
              <p>{item.area}</p>
            </div>
            <div className='info-box'>
              <h4>VUOSI</h4>
              <p>{item.completeYear}</p>
            </div>
          </div>
          <div className='text-presentation'>
            <pre>
              <p>{item.freeText}</p>
            </pre>
          </div>
          {videoUrl ? (
            <div className='text-information-block' id='embed-video-block' style={{ alignItems: 'center' }}>
              <h3>ESITTELYVIDEO</h3>
              <EmbedVideo videoUrl={videoUrl} />
            </div>
          ) : null}
          {floorPlan && floorPlan.compressed ? (
            <div className='text-information-block'>
              <h3>POHJAKUVA</h3>
              <a href={floorPlan.compressed} alt='' target='_blank' rel='noopener noreferrer'>
                <img id='floorPlan' src={floorPlan.compressed} alt='Pohjakuva' />
              </a>
            </div>
          ) : null}
          <div className='text-information-block'>
            <h3>PERUSTIEDOT</h3>
            <div className='text-item'>
              <h6>
                <strong>Kohdenumero</strong>
              </h6>
              <p>{item.identifier}</p>
            </div>
            <div className='text-item'>
              <h6>
                <strong>Sijainti</strong>
              </h6>
              <p>
                {item.address}, {item.postalCode} {item.city}
              </p>
            </div>

            <div className='text-item'>
              <h6>
                <strong>Kaupunginosa</strong>
              </h6>
              <p>{item.districtFree}</p>
            </div>
            {/* {item.rawDataForFiltering?.commissionType === 'SELL' ? ( */}
              <div className='text-item'>
                <h6>
                  <strong>Omistusmuoto</strong>
                </h6>
                {/* <p>Omistusasunto</p> */}
                <p>{item.type}</p>
              </div>
            {/* ) : null} */}
            <div className='text-item'>
              <h6>
                <strong>Tyyppi</strong>
              </h6>
              <p>{item.listingType}</p>
            </div>
            <div className='text-item'>
              <h6>
                <strong>Huoneistoselitelmä</strong>
              </h6>
              <p>{item.typeOfApartment}</p>
            </div>
            <div className='text-item'>
              <h6>
                <strong>Asuintilojen pinta-ala</strong>
              </h6>
              <p>{item.area}</p>
            </div>
            {item.otherArea ? (
              <div className='text-item'>
                <h6>
                  <strong>Muiden tilojen pinta-ala</strong>
                </h6>
                <p>{item.otherArea}</p>
              </div>
            ) : null}
            {item.overallArea ? (
              <div className='text-item'>
                <h6>
                  <strong>Kokonaispinta-ala:</strong>
                </h6>
                <p>{item.overallArea}</p>
              </div>
            ) : null}
            {item.areaMoreInfo ? (
              <div className='text-item'>
                <h6>
                  <strong>Lisätietoja pinta-alasta</strong>
                </h6>
                <p>{item.areaMoreInfo}</p>
              </div>
            ) : null}
            {item.floor && item.floorCount ? (
              <div className='text-item'>
                <h6>
                  <strong>Kerrokset</strong>
                </h6>
                <p>
                  {item.floor} {item.floorCount === 1 ? null : `/ ${item.floorCount}`}
                </p>
              </div>
            ) : null}
            {item.completeYear ? (
              <div className='text-item'>
                <h6>
                  <strong>Rakennusvuosi</strong>
                </h6>
                <p>{item.completeYear}</p>
              </div>
            ) : null}
            <div className='text-item'>
              <h6>
                <strong>Käyttöönottovuosi</strong>
              </h6>
              <p>{item.deploymentYear}</p>
            </div>
            {item.constructionFinalCheckYear ? (
              <div className='text-item'>
                <h6>
                  <strong>Loppukatselmusvuosi</strong>
                </h6>
                <p>{item.constructionFinalCheckYear}</p>
              </div>
            ) : null}
            <div className='text-item'>
              <h6>
                <strong>Vapautuminen</strong>
              </h6>
              <p>
                {item.release === 'Heti' ? 'Heti ' : null}
                {item.releaseDate ? dayjs(item.releaseDate).format('DD.MM.YYYY')+' ' : null}
                {item.release === 'Muu' && item.otherReleaseCondition ? item.otherReleaseCondition : null}
              </p>
            </div>
          </div>
          <div className='text-information-block'>
            <h3>HINTA JA KUSTANNUKSET</h3>
            <div className='text-item'>
              <h6>
                <strong>Velaton hinta</strong>
              </h6>
              <p>{item.debtFreePrice}</p>
            </div>
            <div className='text-item'>
              <h6>
                <strong>Myyntihinta</strong>
              </h6>
              <p>{item.sellingPrice}</p>
            </div>
            {item.debt ? (
              <div className='text-item'>
                <h6>
                  <strong>Velkaosuus</strong>
                </h6>
                <p>{item.debt}</p>
              </div>
            ) : null}
            {/* {item.debtFreePrice && item.area ? (
              <div className="text-item">
                <h6>
                  <strong>Neliöhinta</strong>
                </h6>
                <p>
                  {(
                    parseInt(item.debtFreePrice) /
                    parseFloat(item.area)
                  )
                    .toFixed(2)
                    .toString()
                    .replace('.', ',')}
                  €
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={popover}
                  >
                    <Button
                      variant="laatulkv"
                      style={{
                        borderRadius: '50%',
                        padding: '8px',
                        marginLeft: '10px',
                      }}
                    >
                      <p
                        style={{
                          width: '15px',
                          height: '15px',
                          margin: 'auto',
                          textAlign: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          fontWeight: '700',
                        }}
                      >
                        ?
                      </p>
                    </Button>
                  </OverlayTrigger>
                </p>
              </div>
            ) : null} */}
            {/* {item.rawDataForFiltering.listingType === 'TOWNHOUSE' ? null : (
              <div className='text-item'>
                <h6>
                  <strong>Neliöhinta</strong>
                </h6>
                <p>
                  {item.squarePrice}
                  <OverlayTrigger trigger='click' placement='bottom' overlay={popover}>
                    <Button
                      variant='laatulkv'
                      style={{
                        borderRadius: '50%',
                        padding: '8px',
                        marginLeft: '10px',
                      }}>
                      <p
                        style={{
                          width: '15px',
                          height: '15px',
                          margin: 'auto',
                          textAlign: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          fontWeight: '700',
                        }}>
                        ?
                      </p>
                    </Button>
                  </OverlayTrigger>
                </p>
              </div>
            )} */}
            {item.maintenanceCharge ||
            item.mandatoryCharges > 0 ||
            item.fundingCharge > 0 ? (
              <div className='text-item'>
                <h6>
                  <strong>Yhtiövastike</strong>
                </h6>
                <p>
                  {item.maintenanceCharge}
                  {item.mandatoryCharges || item.fundingCharge ? ' (' : null}
                  {item.mandatoryCharges
                    ? `Hoitovastike ${item.mandatoryCharges} / kk`
                    : null}
                  {item.mandatoryCharges && item.fundingCharge ? ' + ' : null}
                  {item.fundingCharge
                    ? `Rahoitusvastike ${item.fundingCharge} / kk`
                    : null}
                  {item.mandatoryCharges || item.fundingCharge ? ')' : null}
                </p>
              </div>
            ) : null}
            {item.saunaCharge ? (
              <div className='text-item'>
                <h6>
                  <strong>Saunamaksu</strong>
                </h6>
                <p>
                  {item.saunaCharge}
                </p>
              </div>
            ) : null}
            {item.waterCharge ? (
              <div className='text-item'>
                <h6>
                  <strong>Vesimaksu</strong>
                </h6>
                <p>
                  {item.waterCharge}
                </p>
              </div>
            ) : null}
            {item.parkingCharge ? (
              <div className='text-item'>
                <h6>
                  <strong>Autopaikkamaksu</strong>
                </h6>
                <p>
                  {item.parkingCharge}
                </p>
              </div>
            ) : null}
            {item.propertyTax ? (
              <div className='text-item'>
                <h6>
                  <strong>Kiinteistövero</strong>
                </h6>
                <p>{item.propertyTax}</p>
              </div>
            ) : null}
            {item.chargeInfo ? (
              <div className='text-item'>
                <h6>
                  <strong>Lisätiedot maksuista</strong>
                </h6>
                <p>{item.chargeInfo}</p>
              </div>
            ) : null}
          </div>
          <div className='text-information-block'>
            <h3>LISÄTIEDOT</h3>
            {item.otherSpacesInfo ? (
              <div className='text-item'>
                <h6>
                  <strong>Tilojen kuvaus</strong>
                </h6>
                <p>{item.otherSpacesInfo}</p>
              </div>
            ) : null}
            {item.hasBalcony ? (
              <div className='text-item'>
                <h6>
                  <strong>Parveke</strong>
                </h6>
                <p>Kyllä</p>
              </div>
            ) : null}
            {item.balconyTypes || item.balconyCompassPoint ? (
              <div className='text-item'>
                <h6>
                  <strong>Parvekkeen kuvaus</strong>
                </h6>
                <p>
                  Parveketyyppi: {item.balconyTypes} Ilmansuunta:{' '}
                  {item.balconyCompassPoint}
                </p>
              </div>
            ) : null}
            {item.yard ? (
              <div className='text-item'>
                <h6>
                  <strong>Piha</strong>
                </h6>
                <p>{item.yard}</p>
              </div>
            ) : null}
            {item.view ? (
              <div className='text-item'>
                <h6>
                  <strong>Näkymät</strong>
                </h6>
                <p>{item.view}</p>
              </div>
            ) : null}
            {item.hasCableTV ? (
              <div className='text-item'>
                <h6>
                  <strong>Kaapeli-tv</strong>
                </h6>
                <p>Kyllä</p>
              </div>
            ) : null}
            {item.parkingSpace ? (
              <div className='text-item'>
                <h6>
                  <strong>Autopaikka</strong>
                </h6>
                <p>
                  {item.parkingSpace}
                </p>
              </div>
            ) : null}
            {item.propertyHasAntenna ? (
              <div className='text-item'>
                <h6>
                  <strong>Antenni</strong>
                </h6>
                <p>Kyllä</p>
              </div>
            ) : null}
            {item.fireplace ? (
              <div className='text-item'>
                <h6>
                  <strong>Tulisija</strong>
                </h6>
                <p>{item.fireplace}</p>
              </div>
            ) : null}
            {item.dealIncludes ? (
              <div className='text-item'>
                <h6>
                  <strong>Muuta kauppaan kuuluvaa</strong>
                </h6>
                <p>{item.dealIncludes}</p>
              </div>
            ) : null}
            {item.dealDoesNotInclude ? (
              <div className='text-item'>
                <h6>
                  <strong>Kauppaan ei kuulu</strong>
                </h6>
                <p>{item.dealDoesNotInclude}</p>
              </div>
            ) : null}
            {/* {item.pastRenovations ? (
              <div className='text-item'>
                <h6>
                  <strong>Tehdyt remontit</strong>
                </h6>
                <p>{item.pastRenovations}</p>
              </div>
            ) : null} */}
            {item.housingCooperativeElevator ? (
              <div className='text-item'>
                <h6>
                  <strong>Hissi</strong>
                </h6>
                <p>Kyllä</p>
              </div>
            ) : null}
            <div className='text-item'>
              <h6>
                <strong>Asunnon kunto</strong>
              </h6>
              <p>{item.condition}</p>
            </div>
            <div className='text-item'>
              <h6>
                <strong>Energialuokka</strong>
              </h6>
              <p>
                {item.energyClass ? item.energyClass : 'Ei lain edellyttämää energiatodistusta'}
              </p>
            </div>
            {item.energyClassInfo ? (
              <div className='text-item'>
                <h6>
                  <strong>Lisätietoja energiatodistuksesta</strong>
                </h6>
                <p>
                  {item.energyClassInfo}
                </p>
              </div>
            ) : null}
            {item.constructionMaterial ? (
              <div className='text-item'>
                <h6>
                  <strong>Rakennusmateriaalit</strong>
                </h6>
                <p>
                  {item.constructionMaterial}
                </p>
              </div>
            ) : null}
            {item.constructionMaterialInfo ? (
              <div className='text-item'>
                <h6>
                  <strong>Rakennusmateriaalin kuvaus</strong>
                </h6>
                <p>{item.constructionMaterialInfo}</p>
              </div>
            ) : null}
            {item.roofType ? (
              <div className='text-item'>
                <h6>
                  <strong>Kattotyyppi</strong>
                </h6>
                <p>{item.roofType}</p>
              </div>
            ) : null}
            {item.roofingMaterial ? (
              <div className='text-item'>
                <h6>
                  <strong>Kattomateriaalin kuvaus</strong>
                </h6>
                <p>{item.roofingMaterial}</p>
              </div>
            ) : null}
            {item.asbestosSurvey ? (
              <div className='text-item'>
                <h6>
                  <strong>Asbestikartoitus</strong>
                </h6>
                <p>
                  {item.asbestosSurvey ? 'Kyllä' : 'Ei'}
                  {item.asbestosSurveyInfo ? '. ' + item.asbestosSurveyInfo : null}
                </p>
              </div>
            ) : null}
            {item.heatingSystem ? (
              <div className='text-item'>
                <h6>
                  <strong>Lämmitysjärjestelmän kuvaus</strong>
                </h6>
                <p>
                  {item.heatingSystem}
                </p>
              </div>
            ) : null}
            {item.ventilation ? (
              <div className='text-item'>
                <h6>
                  <strong>Ilmastointijärjestelmän kuvaus</strong>
                </h6>
                <p>
                  {item.ventilation}
                </p>
              </div>
            ) : null}
            {item.propertyWaterPipe ? (
              <div className='text-item'>
                <h6>
                  <strong>Vesijohto</strong>
                </h6>
                <p>
                  {item.propertyWaterPipe}
                </p>
              </div>
            ) : null}
            {item.waterPipeInfo ? (
              <div className='text-item'>
                <h6>
                  <strong>Vesihuollon kuvaus</strong>
                </h6>
                <p>
                  {item.waterPipeInfo}
                </p>
              </div>
            ) : null}
            {item.propertySewer ? (
              <div className='text-item'>
                <h6>
                  <strong>Viemäri</strong>
                </h6>
                <p>
                  {item.propertySewer}
                </p>
              </div>
            ) : null}
            {item.electricityContract ? (
              <div className='text-item'>
                <h6>
                  <strong>Sähköliittymä siirtyy</strong>
                </h6>
                <p>Kyllä</p>
              </div>
            ) : null}
            {/* {item.SUBSCRIPTIONS ? (
              <div className='text-item'>
                <h6>
                  <strong>Siirtyvät liittymät</strong>
                </h6>
                <p>{item.SUBSCRIPTIONS}</p>
              </div>
            ) : null} */}
            {item.propertyBuildings ? (
              <div className='text-item'>
                <h6>
                  <strong>Muut rakennukset</strong>
                </h6>
                <p>{item.propertyBuildings}</p>
              </div>
            ) : null}
            {item.propertyBuildingsInfo ? (
              <div className='text-item'>
                <h6>
                  <strong>Lisätietoja muista rakennuksista</strong>
                </h6>
                <p>{item.propertyBuildingsInfo}</p>
              </div>
            ) : null}
            {item.modificationWorkInfo ? (
              <div className='text-item'>
                <h6>
                  <strong>Toimeksiantajan aikana kohteeseen tehdyt toimenpiteet</strong>
                </h6>
                <p>{item.modificationWorkInfo}</p>
              </div>
            ) : null}
          </div>
          <div className='text-information-block'>
            <h3>TILAT JA MATERIAALIT</h3>
            {item.kitchenDescription ? (
              <div className='text-item'>
                <h6>
                  <strong>Keittiön kuvaus</strong>
                </h6>
                <p>{item.kitchenDescription}</p>
              </div>
            ) : null}
            {item.bathroomDescription ? (
              <div className='text-item'>
                <h6>
                  <strong>Kylpyhuoneen kuvaus</strong>
                </h6>
                <p>{item.bathroomDescription}</p>
              </div>
            ) : null}
            {item.wcCount ? (
              <div className='text-item'>
                <h6>
                  <strong>WC-tilojen määrä</strong>
                </h6>
                <p>{item.wcCount}</p>
              </div>
            ) : null}
            {item.wcDescription ? (
              <div className='text-item'>
                <h6>
                  <strong>WC-tilojen kuvaus</strong>
                </h6>
                <p>{item.wcDescription}</p>
              </div>
            ) : null}
            {item.sauna ? (
              <div className='text-item'>
                <h6>
                  <strong>Sauna</strong>
                </h6>
                <p>Oma sauna</p>
              </div>
            ) : null}
            {item.saunaDescription ? (
              <div className='text-item'>
                <h6>
                  <strong>Saunan kuvaus</strong>
                </h6>
                <p>{item.saunaDescription}</p>
              </div>
            ) : null}
            {item.utilityRoomDescription ? (
              <div className='text-item'>
                <h6>
                  <strong>Kodinhoitohuoneen kuvaus</strong>
                </h6>
                <p>{item.utilityRoomDescription}</p>
              </div>
            ) : null}
            {item.livingRoomDescription ? (
              <div className='text-item'>
                <h6>
                  <strong>Olohuoneen kuvaus</strong>
                </h6>
                <p>{item.livingRoomDescription}</p>
              </div>
            ) : null}
            {item.bedroomCount ? (
              <div className='text-item'>
                <h6>
                  <strong>Makuuhuoneiden määrä</strong>
                </h6>
                <p>{item.bedroomCount}</p>
              </div>
            ) : null}
            {item.bedroomDescription ? (
              <div className='text-item'>
                <h6>
                  <strong>Makuuhuoneiden kuvaus</strong>
                </h6>
                <p>{item.bedroomDescription}</p>
              </div>
            ) : null}
            {item.storageSpaceTypes ? (
              <div className='text-item'>
                <h6>
                  <strong>Säilytystilat</strong>
                </h6>
                <p>{item.storageSpaceTypes}</p>
              </div>
            ) : null}
            {item.storageDescription ? (
              <div className='text-item'>
                <h6>
                  <strong>Säilytystilojen kuvaus</strong>
                </h6>
                <p>{item.storageDescription}</p>
              </div>
            ) : null}
          </div>
          {item.housingCooperativeName ? (
            <div className='text-information-block'>
              <h3>TALOYHTIÖ</h3>
              <div className='text-item'>
                <h6>
                  <strong>Taloyhtiön nimi</strong>
                </h6>
                <p>{item.housingCooperativeName}</p>
              </div>
              {item.propertyManagerOffice ||
              item.propertyManagerName ||
              item.propertyManagerEmail ||
              item.propertyManagerPhone ? (
                <div className='text-item'>
                  <h6>
                    <strong>Isännöitsijän yhteystiedot</strong>
                  </h6>
                  <p>{`${item.propertyManagerOffice}, ${item.propertyManagerName}, ${item.propertyManagerEmail}, ${item.propertyManagerPhone}`}</p>
                </div>
              ) : null}
              {item.propertyMaintenance ? (
                <div className='text-item'>
                  <h6>
                    <strong>Huolto</strong>
                  </h6>
                  <p>{item.propertyMaintenance}{item.propertyMaintenanceInfo ? `, ${item.propertyMaintenanceInfo}` : null}</p>
                </div>
              ) : null}
              {item.includes ? (
                <div className='text-item'>
                  <h6>
                    <strong>Taloyhtiöön kuuluu</strong>
                  </h6>
                  <p>
                    {item.includes}
                  </p>
                </div>
              ) : null}
              {/* {item.RC_CONDITION_INFO ? (
                <div className='text-item'>
                  <h6>
                    <strong>Muuta taloyhtiöstä</strong>
                  </h6>
                  <p>{item.RC_CONDITION_INFO}</p>
                </div>
              ) : null} */}
              {item.housingCooperativeParkingSpaces ||
              item.garageCount ||
              item.yardParkingSpaceCount ||
              item.electricPlugParkingSpaceCount ||
              item.parkingGarageCount ||
              item.carPortCount ? (
                <div className='text-item'>
                  <h6>
                    <strong>Taloyhtiön autopaikat</strong>
                  </h6>
                  <div className='parkingInfo'>
                    <p>{item.housingCooperativeParkingSpaces}</p>
                    {item.yardParkingSpaceCount ? (
                      <p>Parkkipaikkoja: {item.yardParkingSpaceCount}</p>
                    ) : null}
                    {item.electricPlugParkingSpaceCount ? (
                      <p>Autopistokepaikkoja: {item.electricPlugParkingSpaceCount}</p>
                    ) : null}
                    {item.carPortCount ? (
                      <p>Autokatospaikkoja: {item.carPortCount}</p>
                    ) : null}
                    {item.parkingGarageCount ? (
                      <p>Autohallipaikkoja: {item.parkingGarageCount}</p>
                    ) : null}
                    {item.garageCount ? <p>Autotalleja: {item.garageCount}</p> : null}
                  </div>
                </div>
              ) : null}
              {item.pastRenovations ? (
                <div className='text-item'>
                  <h6>
                    <strong>Tehdyt remontit</strong>
                  </h6>
                  <p>{item.pastRenovations}</p>
                </div>
              ) : null}
              {item.housingCooperativeUpcomingRenovations ? (
                <div className='text-item'>
                  <h6>
                    <strong>Tulevat remontit</strong>
                  </h6>
                  <p>{item.housingCooperativeUpcomingRenovations}</p>
                </div>
              ) : null}
              {item.housingCooperativeRedemptionRight ? (
                <div className='text-item'>
                  <h6>
                    <strong>Lunastusoikeus</strong>
                  </h6>
                  <p>Kyllä</p>
                </div>
              ) : null}
            </div>
          ) : null}
          <div className='text-information-block'>
            <h3>TONTTI</h3>
            <div className='text-item'>
              <h6>
                <strong>Tontin omistus</strong>
              </h6>
              <p>{item.lotOwnership}</p>
            </div>
            {item.lotType ? (
              <div className='text-item'>
                <h6>
                  <strong>Tontin tyyppi</strong>
                </h6>
                <p>{item.lotType}</p>
              </div>
            ) : null}
            {item.propertyIdentifier ? (
              <div className='text-item'>
                <h6>
                  <strong>Kiinteistötunnus</strong>
                </h6>
                <p>{item.propertyIdentifier}</p>
              </div>
            ) : null}
            <div className='text-item'>
              <h6>
                <strong>Tontin pinta-ala</strong>
              </h6>
              <p>
                {item.lotArea}
              </p>
            </div>
            {item.propertyBuildingRights ? (
              <div className='text-item'>
                <h6>
                  <strong>Rakennusoikeuden pinta-ala</strong>
                </h6>
                <p>{item.propertyBuildingRights}</p>
              </div>
            ) : null}
            {item.propertyBuildingRightsInfo ? (
              <div className='text-item'>
                <h6>
                  <strong>Lisätietoja rakennusoikeudesta</strong>
                </h6>
                <p>{item.propertyBuildingRightsInfo}</p>
              </div>
            ) : null}
            {item.roadTo ? (
              <div className='text-item'>
                <h6>
                  <strong>Tie perille</strong>
                </h6>
                <p>Kyllä</p>
              </div>
            ) : null}
            {item.lotInfo ? (
              <div className='text-item'>
                <h6>
                  <strong>Lisätietoja tontista</strong>
                </h6>
                <p>{item.lotInfo}</p>
              </div>
            ) : null}
            {item.propertyBuildingRightsENumber ? (
              <div className='text-item'>
                <h6>
                  <strong>Rakennukset</strong>
                </h6>
                <p>{item.propertyBuildingRightsENumber}</p>
              </div>
            ) : null}
            {item.zoningInfo ? (
              <div className='text-item'>
                <h6>
                  <strong>Kaavoitustiedot</strong>
                </h6>
                <p>{item.zoningInfo}</p>
              </div>
            ) : null}
            {item.zoningStatus ? (
              <div className='text-item'>
                <h6>
                  <strong>Kaavoitustilanne</strong>
                </h6>
                <p>{item.zoningStatus}</p>
              </div>
            ) : null}
            {item.landYearRent ? (
              <div className='text-item'>
                <h6>
                  <strong>Tontin vuokra</strong>
                </h6>
                <p>
                  {item.landYearRent}
                </p>
              </div>
            ) : null}
            {item.landRenter ? (
              <div className='text-item'>
                <h6>
                  <strong>Tontin vuokraaja</strong>
                </h6>
                <p>{item.landRenter}</p>
              </div>
            ) : null}
            {item.lotRentEndDate ? (
              <div className='text-item'>
                <h6>
                  <strong>Tontin vuokra-aika päättyy</strong>
                </h6>
                <p>{dayjs(item.lotRentEndDate).format('DD.MM.YYYY')}</p>
              </div>
            ) : null}
          </div>
          <div
            className='text-information-block'
            hidden={
              !item.schools && !item.localInfo && !item.kindergarten && !item.trafficConnectionsInfo
                ? true
                : false
            }>
            <h3>PALVELUT JA LIIKENNEYHTEYDET</h3>
            {item.localInfo ? (
              <div className='text-item'>
                <h6>
                  <strong>Paikallistiedot</strong>
                </h6>
                <p>{item.localInfo}</p>
              </div>
            ) : null}
            {item.services ? (
              <div className='text-item'>
                <h6>
                  <strong>Palvelut</strong>
                </h6>
                <p>{item.services}</p>
              </div>
            ) : null}
            {item.schools ? (
              <div className='text-item'>
                <h6>
                  <strong>Koulut</strong>
                </h6>
                <p>{item.schools}</p>
              </div>
            ) : null}
            {item.kindergarten ? (
              <div className='text-item'>
                <h6>
                  <strong>Päiväkodit</strong>
                </h6>
                <p>{item.kindergarten}</p>
              </div>
            ) : null}
            {item.trafficConnections ? (
              <div className='text-item'>
                <h6>
                  <strong>Liikenneyhteydet</strong>
                </h6>
                <p>{item.trafficConnections}</p>
              </div>
            ) : null}
            {item.trafficConnectionsInfo ? (
              <div className='text-item'>
                <h6>
                  <strong>Ajo-ohjeet</strong>
                </h6>
                <p>{item.trafficConnectionsInfo}</p>
              </div>
            ) : null}
          </div>
          <div className='text-information-block mobile-agent'>
            <h3>KOHDETTA VÄLITTÄÄ</h3>
            <div className='mobile-agent-image'>
              <img src={item.realtor.avatar} alt={`${item.realtor.name}`} />
            </div>
            <div className='mobile-agent-container'>
              <div className='mobile-agent-col-1'>
                <h6>
                  <strong>
                    {item.realtor.name}
                  </strong>
                </h6>
                <p>{item.realtor.jobTitle}</p>
              </div>
              <div className='mobile-agent-col-2'>
                <div className='mobile-agent-col-3'>
                  <img src={iconPhone} alt='Puhelin' id='mobile-agent-icon' />
                  <p>{item.realtor.tel}</p>
                </div>
                <div className='mobile-agent-col-3'>
                  <img src={iconEmail} alt='Sähköpostiosoite' id='mobile-agent-icon' />
                  <a href={`mailto:${item.realtor.email}`}>
                    {item.realtor.email}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )

      setRealtyElement(itemElement)
    }
    // eslint-disable-next-line
  }, [item, imageSlides])

  return (
    <div className='Koti animate__animated animate__fadeIn'>
      <Helmet>
        {item ? <title>{item.address}, {item.districtFree}, {item.city} | Laatu LKV | Kiinteistönvälitys Hämeenlinna. Kiinteä ja edullinen välityspalkkio. Hyvä ja kokenut kiinteistönvälittäjä. Asunnon myynti kodistasi välittäen.</title> : null}
      </Helmet>
      <div className='container-realty'>
        <Button
          style={{ color: '#000000', textDecoration: 'none' }}
          variant='laatulkv'
          onClick={() => history.push('/kodit?filter=10000000000')}>
          Takaisin Kodit-sivulle
        </Button>
        {realtyElement}
      </div>
      {realtyAgent}
    </div>
  )
}

export default Koti
