import './Mika.scss'
import { Link } from 'react-router-dom'
import mika from '../../images/mika-lehtonen-kuva.jpg'
import iconPhone from '../../images/phone-alt-solid.svg'
import iconEmail from '../../images/at-solid.svg'
import { useSelector } from 'react-redux'

const Mika = () => {
  const loading = useSelector((state) => state.general.loading)
  return (
    <div className="Mika" hidden={loading}>
      <div className="MikaColText">
        <Link className="linkTitle" to="/laatulkv#mika">
          <h2 className="customSubTitle">Mika Lehtonen</h2>
          <h5>Kiinteistönvälittäjä LKV, Kaupanvahvistaja</h5>
        </Link>
        <p>
          Olen helposti lähestyttävä ja luotettava. Asiantunteva välitystapani
          takaa tehokkuuden ja hyvät tulokset: kotisi ansaitsee tulla
          välitetyksi aidolla ammattitaidolla.
        </p>
        <div className="henkiloYhteystiedot">
          <div>
            <img src={iconPhone} alt="Puhelin" />
            050 476 2332
          </div>
          <div>
            <img src={iconEmail} alt="Sähköpostiosoite" />
            <a href="mailto:mika@laatulkv.fi">mika@laatulkv.fi</a>
          </div>
        </div>
      </div>

      <div className="MikaColImage">
        <img src={mika} alt="Mika Lehtonen" />
      </div>
    </div>
  )
}

export default Mika
