import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { Form, Button } from 'react-bootstrap'
import hostname from '../../hostname'
import './Kodit.scss'

const Kodit = () => {
  const history = useHistory()
  const location = useLocation()

  function useQuery() {
    return new URLSearchParams(location.search)
  }

  let query = useQuery()
  const init = query.get('filter')

  const [filter, setFilter] = useState(init ? [...init] : ['1', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])

  const [realties, setRealties] = useState(undefined)
  const [realtiesRent, setRealtiesRent] = useState(undefined)

  const [filteredRealties, setFilteredRealties] = useState(undefined)
  const [filteredRealtiesRent, setFilteredRealtiesRent] = useState(undefined)

  const [forSaleList, setForSaleList] = useState(<div />)
  const [forRentList, setForRentList] = useState(<div />)

  const [forSale, toggleForSale] = useState(filter[0] === '1' ? true : false)

  const [roomsOne, toggleRoomsOne] = useState(filter[1] === '1' ? true : false)
  const [roomsTwo, toggleRoomsTwo] = useState(filter[2] === '1' ? true : false)
  const [roomsThree, toggleRoomsThree] = useState(filter[3] === '1' ? true : false)
  const [roomsFour, toggleRoomsFour] = useState(filter[4] === '1' ? true : false)
  const [roomsFive, toggleRoomsFive] = useState(filter[5] === '1' ? true : false)

  const [rivitalo, toggleRivitalo] = useState(filter[6] === '1' ? true : false)
  const [paritalo, toggleParitalo] = useState(filter[7] === '1' ? true : false)
  const [kerrostalo, toggleKerrostalo] = useState(filter[8] === '1' ? true : false)
  const [omakotitalo, toggleOmakotitalo] = useState(filter[9] === '1' ? true : false)
  const [puutalo, togglePuutalo] = useState(filter[10] === '1' ? true : false)
  const [lomaAsunto, toggleLomaAsunto] = useState(filter[11] === '1' ? true : false)

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const resetFilters = () => {
    toggleRoomsOne(false)
    toggleRoomsTwo(false)
    toggleRoomsThree(false)
    toggleRoomsFour(false)
    toggleRoomsFive(false)
    toggleKerrostalo(false)
    toggleRivitalo(false)
    toggleParitalo(false)
    toggleOmakotitalo(false)
    togglePuutalo(false)
    toggleLomaAsunto(false)
  }

  async function getRealties() {
    try {
      const res = await axios.get(`${hostname}/api/realties-for-sale`)
      const res2 = await axios.get(`${hostname}/api/main-images-sale`)

      const realties = res.data
      let i

      for (i = 0; i < realties.length; i++) {
        realties[i].mainImage = res2.data[i]
      }
      setRealties(realties)
    } catch (error) {
      console.error(error)
    }
  }

  async function getRealtiesRent() {
    try {
      const res = await axios.get(`${hostname}/api/realties-for-rent`)
      const res2 = await axios.get(`${hostname}/api/main-images-rent`)

      const realties = res.data
      let i

      for (i = 0; i < realties.length; i++) {
        realties[i].mainImage = res2.data[i]
      }

      setRealtiesRent(realties)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getRealties()
  }, [])

  useEffect(() => {
    let url = []
    forSale ? (url[0] = '1') : (url[0] = '0')
    roomsOne ? (url[1] = '1') : (url[1] = '0')
    roomsTwo ? (url[2] = '1') : (url[2] = '0')
    roomsThree ? (url[3] = '1') : (url[3] = '0')
    roomsFour ? (url[4] = '1') : (url[4] = '0')
    roomsFive ? (url[5] = '1') : (url[5] = '0')
    rivitalo ? (url[6] = '1') : (url[6] = '0')
    paritalo ? (url[7] = '1') : (url[7] = '0')
    kerrostalo ? (url[8] = '1') : (url[8] = '0')
    omakotitalo ? (url[9] = '1') : (url[9] = '0')
    puutalo ? (url[10] = '1') : (url[10] = '0')
    lomaAsunto ? (url[11] = '1') : (url[11] = '0')
    setFilter(url)
  }, [
    forSale,
    roomsOne,
    roomsTwo,
    roomsThree,
    roomsFour,
    roomsFive,
    rivitalo,
    paritalo,
    kerrostalo,
    omakotitalo,
    puutalo,
    lomaAsunto
  ])

  useEffect(() => {
    if (filter) history.replace(`/kodit?filter=${filter.join('')}`)
    // eslint-disable-next-line
  }, [filter])

  useEffect(() => {
    if (!forSale && !realtiesRent) {
      getRealtiesRent()
    }
  }, [forSale, realtiesRent])

  useEffect(() => {
    if (realties) {
      let temp = realties

      if (roomsOne || roomsTwo || roomsThree || roomsFour || roomsFive) {
        if (!roomsOne) temp = temp.filter((el) => el.roomCount !== '1')
        if (!roomsTwo) temp = temp.filter((el) => el.roomCount !== '2')
        if (!roomsThree) temp = temp.filter((el) => el.roomCount !== '3')
        if (!roomsFour) temp = temp.filter((el) => el.roomCount !== '4')
        if (!roomsFive) {
          temp = temp.filter((el) => el.roomCount !== '5')
          temp = temp.filter((el) => el.roomCount !== '6')
          temp = temp.filter((el) => el.roomCount !== '7')
          temp = temp.filter((el) => el.roomCount !== '8')
          temp = temp.filter((el) => el.roomCount !== '9')
        }
      }

      if (rivitalo || paritalo || kerrostalo || omakotitalo || puutalo || lomaAsunto) {
        if (!rivitalo) temp = temp.filter((el) => el.rawDataForFiltering.listingType !== 'ROWHOUSE')
        if (!paritalo) temp = temp.filter((el) => el.rawDataForFiltering.listingType !== 'PAIRHOUSE')
        if (!kerrostalo) temp = temp.filter((el) => el.rawDataForFiltering.listingType !== 'FLAT')
        if (!omakotitalo) temp = temp.filter((el) => el.rawDataForFiltering.listingType !== 'DETACHEDHOUSE')
        if (!puutalo) temp = temp.filter((el) => el.rawDataForFiltering.listingType !== 'WOODEN_HOUSE_SHARE')
        if (!lomaAsunto) temp = temp.filter((el) => el.rawDataForFiltering.productGroup !== 'VACATION_APARTMENT')
      }

      setFilteredRealties(temp)
    }
  }, [
    realties,
    roomsOne,
    roomsTwo,
    roomsThree,
    roomsFour,
    roomsFive,
    rivitalo,
    paritalo,
    kerrostalo,
    omakotitalo,
    puutalo,
    lomaAsunto
  ])

  useEffect(() => {
    if (realtiesRent) {
      let temp = realtiesRent

      if (roomsOne || roomsTwo || roomsThree || roomsFour || roomsFive) {
        if (!roomsOne) temp = temp.filter((el) => el.roomCount !== '1')
        if (!roomsTwo) temp = temp.filter((el) => el.roomCount !== '2')
        if (!roomsThree) temp = temp.filter((el) => el.roomCount !== '3')
        if (!roomsFour) temp = temp.filter((el) => el.roomCount !== '4')
        if (!roomsFive) {
          temp = temp.filter((el) => el.roomCount !== '5')
          temp = temp.filter((el) => el.roomCount !== '6')
          temp = temp.filter((el) => el.roomCount !== '7')
          temp = temp.filter((el) => el.roomCount !== '8')
          temp = temp.filter((el) => el.roomCount !== '9')
        }
      }

      if (rivitalo || paritalo || kerrostalo || omakotitalo || puutalo || lomaAsunto) {
        if (!rivitalo) temp = temp.filter((el) => el.rawDataForFiltering.listingType !== 'ROWHOUSE')
        if (!paritalo) temp = temp.filter((el) => el.rawDataForFiltering.listingType !== 'PAIRHOUSE')
        if (!kerrostalo) temp = temp.filter((el) => el.rawDataForFiltering.listingType !== 'FLAT')
        if (!omakotitalo) temp = temp.filter((el) => el.rawDataForFiltering.listingType !== 'DETACHEDHOUSE')
        if (!puutalo) temp = temp.filter((el) => el.rawDataForFiltering.listingType !== 'WOODEN_HOUSE_SHARE')
        if (!lomaAsunto) temp = temp.filter((el) => el.rawDataForFiltering.productGroup !== 'VACATION_APARTMENT')
      }

      setFilteredRealtiesRent(temp)
    }
  }, [
    realtiesRent,
    roomsOne,
    roomsTwo,
    roomsThree,
    roomsFour,
    roomsFive,
    rivitalo,
    paritalo,
    kerrostalo,
    omakotitalo,
    puutalo,
    lomaAsunto
  ])

  const handleRealtyClick = (id) => {
    sessionStorage.setItem('scrollPosition', window.scrollY)
    history.push(`/koti/${id}`)
  }

  useEffect(() => {
    if (filteredRealties) {
      const realtiesElements = filteredRealties.map((el, i) => (
        <div className='realty' key={el.identifier} onClick={() => handleRealtyClick(el.identifier)}>
          <img src={el.mainImage} alt={el.address} width='375' height='250' />
          <div className='textContainer'>
            <h3>
              {capitalizeFirstLetter(el.listingType)} | {el.typeOfApartment}
            </h3>
            <h2>
              {el.address}, {el.districtFree}, {el.city}
            </h2>

            <div className='infoContainer'>
              <div className='infoBox'>
                <h4>HINTA</h4>
                <p>{el.debtFreePrice}</p>
              </div>
              <div className='infoBox'>
                <h4>KOKO</h4>
                <p>{el.area}</p>
              </div>
              <div className='infoBox'>
                <h4>VUOSI</h4>
                <p>{el.completeYear}</p>
              </div>
            </div>
          </div>
        </div>
      ))
      setForSaleList(realtiesElements)
    }
    // eslint-disable-next-line
  }, [filteredRealties])

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem('scrollPosition')
    if (scrollPosition && forSaleList?.length) {
      window.scrollTo(0, parseInt(scrollPosition))
      sessionStorage.removeItem('scrollPosition')
    }
    // eslint-disable-next-line
  }, [forSaleList])

  useEffect(() => {
    if (filteredRealtiesRent) {
      const realtiesElements = filteredRealtiesRent.map((el, i) => (
        <div className='realty' key={el.identifier} onClick={() => history.push(`/vuokrakoti/${el.identifier}`)}>
          <img src={el.mainImage} alt={el.address} width='375' height='250' />
          <div className='textContainer'>
            <h3>
              {el.listingType} | {el.typeOfApartment}
            </h3>
            <h2>
              {el.address}, {el.districtFree}, {el.city}
            </h2>

            <div className='infoContainer'>
              <div className='infoBox'>
                <h4>VUOKRA</h4>
                <p>{el.rent}</p>
              </div>
              <div className='infoBox'>
                <h4>KOKO</h4>
                <p>{el.area}</p>
              </div>
              <div className='infoBox'>
                <h4>VUOSI</h4>
                <p>{el.completeYear}</p>
              </div>
            </div>
          </div>
        </div>
      ))
      setForRentList(realtiesElements)
    }
    // eslint-disable-next-line
  }, [filteredRealtiesRent])

  return (
    <div className='Kodit animate__animated animate__fadeIn'>
      <Helmet>
        <title>Laatu LKV | Kodit | Kiinteistönvälitys Hämeenlinna. Kiinteä ja edullinen välityspalkkio. Hyvä ja kokenut kiinteistönvälittäjä. Asunnon myynti kodistasi välittäen.</title>
      </Helmet>
      <div className='realtyFilterContainer'>
        <div className='realtyFilterBox' style={{ justifyContent: 'center' }}>
          <h1 className='customSubTitle'>Kodit</h1>
        </div>
        <div className='realtyFilterBox'>
          <h6>Toimeksianto</h6>
          <Form.Group>
            <Form.Check
              name='forSale'
              type='radio'
              label='Myytävänä'
              defaultChecked={forSale}
              value={forSale}
              onChange={() => toggleForSale(true)}
            />
            <Form.Check
              name='forSale'
              type='radio'
              label='Vuokrattavana'
              defaultChecked={!forSale}
              value={!forSale}
              onChange={() => toggleForSale(false)}
            />
          </Form.Group>
        </div>

        <div className='realtyFilterBox'>
          <h6>Huoneluku</h6>
          <Form.Group>
            <Form.Check
              name='roomsOne'
              type='checkbox'
              label='1h'
              checked={roomsOne}
              onChange={() => toggleRoomsOne(!roomsOne)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              name='roomsTwo'
              type='checkbox'
              label='2h'
              checked={roomsTwo}
              onChange={() => toggleRoomsTwo(!roomsTwo)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              name='roomsThree'
              type='checkbox'
              label='3h'
              checked={roomsThree}
              onChange={() => toggleRoomsThree(!roomsThree)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              name='roomsFour'
              type='checkbox'
              label='4h'
              checked={roomsFour}
              onChange={() => toggleRoomsFour(!roomsFour)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              name='roomsFive'
              type='checkbox'
              label='5h+'
              checked={roomsFive}
              onChange={() => toggleRoomsFive(!roomsFive)}
            />
          </Form.Group>
        </div>

        <div className='realtyFilterBox'>
          <h6>Asuntotyyppi</h6>
          <Form.Group>
            <Form.Check
              name='rivitalo'
              type='checkbox'
              label='Rivitalo'
              checked={rivitalo}
              onChange={() => toggleRivitalo(!rivitalo)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              name='paritalo'
              type='checkbox'
              label='Paritalo'
              checked={paritalo}
              onChange={() => toggleParitalo(!paritalo)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              name='kerrostalo'
              type='checkbox'
              label='Kerrostalo'
              checked={kerrostalo}
              onChange={() => toggleKerrostalo(!kerrostalo)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              name='omakotitalo'
              type='checkbox'
              label='Omakotitalo'
              checked={omakotitalo}
              onChange={() => toggleOmakotitalo(!omakotitalo)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              name='puutalo'
              type='checkbox'
              label='Puutalo-osake'
              checked={puutalo}
              onChange={() => togglePuutalo(!puutalo)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              name='lomaAsunto'
              type='checkbox'
              label='Loma-asunto'
              checked={lomaAsunto}
              onChange={() => toggleLomaAsunto(!lomaAsunto)}
            />
          </Form.Group>
        </div>
        <div className='realtyFilterBox'>
          <Button variant='light' className='rajaaHakuaButton' onClick={resetFilters}>
            Tyhjennä
          </Button>
        </div>
      </div>

      <div className='container-realty'>
        <p className='currentResult'>
          {forSale
            ? `Myytävänä yhteensä ${realties ? realties.length : '0'} kotia. `
            : `Vuokrattavana yhteensä ${realtiesRent ? realtiesRent.length : '0'} kotia. `}
        </p>
        <p className='currentResult'>
          {forSale &&
          (roomsOne ||
            roomsTwo ||
            roomsThree ||
            roomsFour ||
            roomsFive ||
            paritalo ||
            rivitalo ||
            kerrostalo ||
            omakotitalo ||
            puutalo ||
            lomaAsunto)
            ? `Valinnoillasi näytetään ${filteredRealties ? filteredRealties.length : '0'} kotia.`
            : null}
          {!forSale &&
          (roomsOne ||
            roomsTwo ||
            roomsThree ||
            roomsFour ||
            roomsFive ||
            paritalo ||
            rivitalo ||
            kerrostalo ||
            omakotitalo ||
            puutalo ||
            lomaAsunto)
            ? `Valinnoillasi näytetään ${filteredRealtiesRent ? filteredRealtiesRent.length : '0'} kotia.`
            : null}
        </p>
        {forSale ? forSaleList : forRentList}
      </div>
    </div>
  )
}

export default Kodit
