import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Carousel from 'react-multi-carousel'
import './Uusimmat.scss'
import 'react-multi-carousel/lib/styles.css'

const Uusimmat = () => {
  const loading = useSelector((state) => state.general.loading)
  const realties = useSelector((state) => state.general.realties)
  const [menuItems, setMenuItems] = useState([])

  const responsive = {
    bigDesktop: {
      breakpoint: { max: 3000, min: 1500 },
      items: 4,
      slidesToSlide: 2,
    },
    smallDesktop: {
      breakpoint: { max: 1500, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  useEffect(() => {
    if (realties) {
      const lastElement = (
        <Link to={`/kodit?filter=10000000000`} key={'kaikkiKodit'}>
          <div className="menu-item last-item">
            <h6>Katso täältä kaikki kodit</h6>
          </div>
        </Link>
      )
      const firstEight = realties.slice(0, 8)
      const list = firstEight.map((el) => (
        <Link to={`/koti/${el.identifier}`} key={el.identifier}>
          <div className="menu-item">
            <img src={el.mainImage} alt={el.address} />
            <div className="uusimmat-textContainer">
              <h3>
                {capitalizeFirstLetter(el.listingType)} | {el.typeOfApartment}
              </h3>
              <h2>
                {el.address}, {el.districtFree}, {el.city}
              </h2>

              <div className="uusimmat-infoContainer">
                <div className="uusimmat-infoBox">
                  <h4>HINTA</h4>
                  <p>
                    {el.debtFreePrice}
                  </p>
                </div>
                <div className="uusimmat-infoBox">
                  <h4>KOKO</h4>
                  <p>
                    {el.area}
                  </p>
                </div>
                <div className="uusimmat-infoBox">
                  <h4>VUOSI</h4>
                  <p>{el.completeYear}</p>
                </div>
              </div>
            </div>
          </div>
        </Link>
      ))
      list.push(lastElement)
      setMenuItems(list)
    }
  }, [realties])

  return (
    <div className="Uusimmat" hidden={loading}>
      <div className="UusimmatColText">
        <Link className="linkTitle" to="/kodit?filter=10000000000">
          <h2 className="customSubTitle">Uusimmat kodit</h2>
        </Link>
        <p>Tässä kahdeksan uusinta myyntiin julkaistua kotia.</p>
      </div>
      <div className="scrollMenu">
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={false}
          responsive={responsive}
          infinite={false}
          autoPlay={false}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={[]}
          dotListClass="custom-dot-list-style"
          itemClass="menu-item"
        >
          {menuItems.length > 0 ? menuItems : <div />}
        </Carousel>
      </div>
    </div>
  )
}

export default Uusimmat
