// React
import { useEffect, useState } from 'react'

// React Router Dom
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'

// Google Analytics
import ReactGA from 'react-ga'

// React Components
import Navbar from './components/NavBar/Navbar'
import NavBarMobile from './components/NavBarMobile/NavBarMobile'
import Footer from './components/Footer/Footer'
import LoadingScreen from './components/LoadingScreen/LoadingScreen'
import GDPR from './components/GDPR'

// React Pages
import Etusivu from './pages/Etusivu/Etusivu'
import Laatulkv from './pages/Laatulkv/Laatulkv'
import Palvelut from './pages/Palvelut/Palvelut'
import Kodit from './pages/Kodit/Kodit'
import Koti from './pages/Koti/Koti'
import VuokraKoti from './pages/VuokraKoti/VuokraKoti'
import Kuvat from './pages/Kuvat/Kuvat'
import VuokraKuvat from './pages/VuokraKuvat/VuokraKuvat'
import OtaYhteytta from './pages/OtaYhteytta/OtaYhteytta'
import Yhteistyossa from './pages/Yhteistyossa/Yhteistyossa'
import Kirjaudu from './pages/Kirjaudu/Kirjaudu'
import Asetukset from './pages/Asetukset/Asetukset'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Evastekaytanto from './pages/Evastekaytanto/Evastekaytanto'
import Vuokrahakemus from './pages/Vuokrahakemus/Vuokrahakemus'
import Palaute from './pages/Palautelomakkeet/Palaute/Palaute'
import PalauteOstinKodin from './pages/Palautelomakkeet/PalauteOstinKodin/PalauteOstinKodin'
import PalauteMyinKodin from './pages/Palautelomakkeet/PalauteMyinKodin/PalauteMyinKodin'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { actions } from './redux/actions'

// Hooks
import { useCookie } from './utils/cookies'

import './App.scss'
import 'animate.css'

const App = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const loading = useSelector((state) => state.general.loading)
  const realties = useSelector((state) => state.general.realties)
  const sold = useSelector((state) => state.general.sold)
  const fbPosts = useSelector((state) => state.general.fbPosts)

  const updateWindowDimensions = () => {
    setScreenWidth(window.innerWidth)
  }

  window.addEventListener('resize', updateWindowDimensions)

  useEffect(() => {
    if (!realties) {
      dispatch(actions.getRealties())
    }
    if (!sold) {
      dispatch(actions.getSoldAmount())
    }
    if (!fbPosts) {
      dispatch(actions.getFbPosts())
    }
    // eslint-disable-next-line
  }, [realties])

  useEffect(() => {
    setTimeout(function () {
      dispatch({ type: 'LOADING_DONE' })
    }, 2500)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    return function cleanUp() {
      window.removeEventListener('resize', updateWindowDimensions)
    }
  })

  // undefined = ei vastausta
  // all = hyväksy kaikki
  // minimum = vain välttämättömät
  const cookieName = 'laatulkv.fi_cookie_consent='
  const [cookieConsent, updateCookieConsent] = useCookie(cookieName, undefined)
  const [cookieModalVisible, setCookieModalVisible] = useState(false)

  useEffect(() => {
    if (window.location.hostname !== 'localhost' && cookieConsent === 'all') {
      ReactGA.initialize('G-2KL6Q2ZW7Z')
    }
  }, [cookieConsent, updateCookieConsent])

  useEffect(() => {
    if (cookieConsent === 'all') ReactGA.pageview(location.pathname + location.search)
    // eslint-disable-next-line
  }, [location])

  return (
    <div className="App">
      {(loading && location.pathname === '') ||
      (loading && location.pathname === '/') ? (
        <LoadingScreen />
      ) : null}

      {screenWidth > 1024 ? <Navbar /> : <NavBarMobile />}
      <GDPR cookieConsent={cookieConsent} updateCookieConsent={updateCookieConsent} cookieModalVisible={cookieModalVisible} setCookieModalVisible={setCookieModalVisible} />
      <ScrollToTop />

      <Switch>
        <Route exact path="/">
          <Etusivu screenWidth={screenWidth} />
        </Route>
        <Route exact path="/laatulkv" component={Laatulkv} />
        <Route exact path="/palvelut" component={Palvelut} />
        <Route exact path="/kodit" component={Kodit} />
        <Route exact path="/koti/:id" component={Koti} />
        <Route exact path="/vuokrakoti/:id" component={VuokraKoti} />
        <Route exact path="/kuvat/:id" component={Kuvat} />
        <Route exact path="/vuokrakuvat/:id" component={VuokraKuvat} />
        <Route exact path="/ota-yhteytta" component={OtaYhteytta} />
        <Route exact path="/vuokra-asuntohakemus" component={Vuokrahakemus} />
        <Route exact path="/yhteistyossa" component={Yhteistyossa} />
        <Route exact path="/palaute" component={Palaute} />
        <Route exact path="/evastekaytanto" render={() => <Evastekaytanto setCookieModalVisible={setCookieModalVisible} />} />
        <Route
          exact
          path="/palaute-ostin-kodin"
          component={PalauteOstinKodin}
        />
        <Route exact path="/palaute-myin-kodin" component={PalauteMyinKodin} />
        <Route exact path="/kirjaudu" component={Kirjaudu} />
        <Route exact path="/asetukset" component={Asetukset} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>

      <Footer />
    </div>
  )
}

export default App
