import './Piia.scss'
import { Link } from 'react-router-dom'
import piia from '../../images/piia-heikkilä-kuva.jpg'
import iconPhone from '../../images/phone-alt-solid.svg'
import iconEmail from '../../images/at-solid.svg'
import { useSelector } from 'react-redux'

const Piia = () => {
  const loading = useSelector((state) => state.general.loading)

  return (
    <div className="Piia" hidden={loading}>
      <div className="PiiaColText">
        <Link className="linkTitle" to="/laatulkv#piia">
          <h2 className="customSubTitle">Piia Heikkilä</h2>
          <h5>Yrittäjä, Kiinteistönvälittäjä LKV, Kaupanvahvistaja</h5>
        </Link>
        <p>
          Olen luotettava, helposti lähestyttävä sekä periksiantamaton.
          Kiinteistönvälitys ja asiakaspalvelu ovat intohimojani: teen kotisi
          myynnistä sinulle mahdollisimman helppoa ja mukavaa,
          asiakaslähtöisesti ja suurella sydämellä.
        </p>
        <div className="henkiloYhteystiedot">
          <div>
            <img src={iconPhone} alt="Puhelin" />
            050 452 5254
          </div>
          <div>
            <img src={iconEmail} alt="Sähköpostiosoite" />
            <a href="mailto:piia@laatulkv.fi">piia@laatulkv.fi</a>
          </div>
        </div>
      </div>

      <div className="PiiaColImage">
        <img src={piia} alt="Piia Heikkilä" />
      </div>
    </div>
  )
}

export default Piia
