import { GET_REALTIES, GET_SOLD_AMOUNT, GET_FB_POSTS, CHECK_AUTH, UPDATE_DATA } from './types'
import axios from 'axios'
import hostname from './../hostname'

export const getRealties = () => async (dispatch) => {
  try {
    const res = await axios.get(`${hostname}/api/realties-for-sale`)
    const res2 = await axios.get(`${hostname}/api/main-images-sale`)

    const realties = res.data
    let i

    for (i = 0; i < realties.length; i++) {
      realties[i].mainImage = res2.data[i]
    }

    if (res.status === 200 && res2.status === 200 && realties) {
      return dispatch({
        type: GET_REALTIES,
        payload: realties,
      })
    }
  } catch (error) {
    console.error(error)
  }
}

export const getSoldAmount = () => async (dispatch) => {
  try {
    const res = await axios.get(`${hostname}/api/realties-sold`)

    if (res.status === 200) {
      return dispatch({
        type: GET_SOLD_AMOUNT,
        payload: parseInt(res.data),
      })
    }
  } catch (error) {
    console.error(error)
  }
}

export const getFbPosts = () => async (dispatch) => {
  try {
    const res = await axios.get(`${hostname}/api/fb-posts`)

    if (res.status === 200) {
      return dispatch({
        type: GET_FB_POSTS,
        payload: res.data,
      })
    }
  } catch (error) {
    console.error(error)
  }
}

export const checkAuth = () => (dispatch) => {
  try {
    const token = localStorage.getItem('laatulkv-token')
    const isAuthenticated = token === 'mo32zf0jnFS030gm3s8BqDl5meCi2p' ? true : false

    return dispatch({
      type: CHECK_AUTH,
      payload: isAuthenticated,
    })
  } catch (error) {
    console.error(error)
  }
}

export const updateData = (sold, fbposts) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'laatulkv-secret': 'ndNT9v43JFSD0mo12jqdmf7wsApQ5ov',
    },
  }

  const postsFormatted = fbposts.replace(/\s+/g, '').split(',')

  const body = JSON.stringify({
    sold: sold,
    fbposts: postsFormatted,
  })

  try {
    const res = await axios.post(`${hostname}/api/update-data`, body, config)

    if (res.status === 200) {
      dispatch({
        type: UPDATE_DATA,
        payload: true,
      })
    }
  } catch (error) {
    console.error(error)
  }
}
