import { Modal, Button } from "react-bootstrap"
// import SwitchComponent from "../Switch"
import Switch from "react-switch"

const ModalGDPR = (props) => {

const { visible, setVisible, cookieConsent, updateCookieConsent } = props
const closeHandler = () => {
setVisible(false)
}

  return (
    <div>
      <Modal
        backdrop="static"
        aria-labelledby="modal-title"
        show={visible}
        onHide={closeHandler}
      >
        <Modal.Header>
            <h3 style={{ fontSize: '18px', margin: 'auto' }}><strong>
              Evästeasetukset
              </strong>
            </h3>
        </Modal.Header>
        <Modal.Body>
            <p>Valitse sinulle sopiva evästeasetus. Hyväksymällä kaikki evästeet sallit sivuston kerätä analyyttisia tietoja kuten sivuvierailut ja istunnon kesto. Analyyttisten tietojen avulla ei voida yksilöidä tai tunnistaa käyttäjiä.</p>
            <div className='cookie-switch'>
                <Switch color="warning" checked={cookieConsent === 'all'} onChange={() => updateCookieConsent(cookieConsent === 'all' ? 'minimum' : 'all')} />
                <span className='cs-text'><strong>{cookieConsent === 'all' ? 'Kaikki evästeet' : 'Vain välttämättömät evästeet'}</strong></span>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={closeHandler} style={{ color: '#000', background: '#fecbb8', margin: 'auto', fontSize: '16px', width: 'fit-content' }}>
            Sulje
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ModalGDPR
