import './LoadingScreen.scss'
import logo from '../../images/laatulkv_logo.png'
import { Spinner } from 'react-bootstrap'

const LoadingScreen = () => {
  return (
    <div className="LoadingScreen">
      <div className="animate__animated animate__zoomIn">
        <img src={logo} alt="Laatu LKV" width="240" />
        <div>
          <p>Ladataan...</p>
          <Spinner animation="grow" />
        </div>
      </div>
    </div>
  )
}

export default LoadingScreen
