// React Router Dom
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Styling & Utilities
import logo from '../../images/laatulkv_logo.png'
import fb from '../../images/facebook-brands-white.svg'
import instagram from '../../images/instagram-brands-white.svg'
import './Navbar.scss'

const Navbar = () => {
  const location = useLocation()
  const history = useHistory()

  const goToLanding = () => {
    if (location.pathname === '/') {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    } else {
      history.push('/')
      window.scrollTo(0, 0)
    }
  }

  const loading = useSelector((state) => state.general.loading)

  return (
    <div
      className="Navbar"
      hidden={
        (loading && location.pathname === '/') ||
        (loading && location.pathname === '') ||
        location.pathname === '/palaute' ||
        location.pathname === '/palaute/' ||
        location.pathname === '/palaute-ostin-kodin' ||
        location.pathname === '/palaute-ostin-kodin/' ||
        location.pathname === '/palaute-myin-kodin' ||
        location.pathname === '/palaute-myin-kodin/'
      }
    >
      <img src={logo} alt="Laatu LKV" height="58" onClick={goToLanding} />
      <nav className="linkContainer">
        <Link to="/" onClick={goToLanding}>
          ETUSIVU
        </Link>
        <Link to="/laatulkv">LAATU LKV</Link>
        <Link to="/palvelut">PALVELUT</Link>
        <Link to="/kodit?filter=10000000000">KODIT</Link>
        <Link to="/ota-yhteytta">OTA YHTEYTTÄ</Link>
        <Link to="/yhteistyossa">YHTEISTYÖSSÄ</Link>
        <p className="linkDecoration">|</p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/laatulkv/"
        >
          <img src={fb} alt="Facebook logo" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/laatulkv/?hl=fi"
        >
          <img src={instagram} alt="Instagram logo" />
        </a>
      </nav>
    </div>
  )
}

export default Navbar
