import {
  LOADING_DONE,
  GET_REALTIES,
  GET_SOLD_AMOUNT,
  GET_FB_POSTS,
  CHECK_AUTH,
  UPDATE_DATA,
} from './types'

const initialState = {
  loading: true,
  realties: undefined,
  sold: undefined,
  fbPosts: undefined,
  isAuthenticated: false,
  updateDone: false,
}

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case LOADING_DONE:
      return {
        ...state,
        loading: false,
      }

    case GET_REALTIES:
      return {
        ...state,
        realties: payload,
      }

    case GET_SOLD_AMOUNT:
      return {
        ...state,
        sold: payload,
      }

    case GET_FB_POSTS:
      return {
        ...state,
        fbPosts: payload,
      }

    case CHECK_AUTH:
      return {
        ...state,
        isAuthenticated: payload,
      }

    case UPDATE_DATA:
      return {
        ...state,
        updateDone: payload,
      }

    default:
      return state
  }
}
