import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Button } from 'react-bootstrap'
import './Evastekaytanto.scss'

const OtaYhteytta = (props) => {

  const { setCookieModalVisible } = props 

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='Evastekaytanto animate__animated animate__fadeIn'>
      <Helmet>
        <title>Laatu LKV - Evästekäytäntö</title>
      </Helmet>
      <h2 className='customSubTitle' style={{ backgroundColor: '#fecbb8', padding: '16px 16px 12px 12px' }}>
        Evästekäytäntö
      </h2>
      <p>
  Eväste (englanniksi Cookie) on pieni tekstitiedosto, jonka verkkoselain tallentaa kävijän päätelaitteelle verkkosivustoa käytettäessä. 
  Käytämme verkkosivullamme evästeitä kerätäksemme tietoa sivuston käytöstä. Näiden tietojen avulla pyrimme analysoimaan ja kehittämään sivustoa sekä palveluitamme. 
  Käyttäjää ei voida tunnistaa pelkkien evästeiden avulla. Voit myös lukea tietosuojaselosteemme tämän sivun alareunasta löytyvästä linkistä.
</p>
<p>
  Voit hyväksyä kaikki evästeet ruudun alareunassa olevasta ilmoituspalkista, tai vaihtoehtoisesti valita itsellesi sopivan evästeasetuksen evästeasetuksista.
  Huomioi, että jos et hyväksy kaikkien evästeiden käyttöä sivustolla, eivät sivuston kaikki toiminnot välttämättä toimi oikein laitteellasi.
</p>
<Button
variant='light'
style={{ color: '#000', margin: '1rem auto 0', fontSize: '16px', width: 'fit-content' }}
onClick={() => setCookieModalVisible(true)}
>
  Evästeasetukset
</Button>
<p>
        Lue tästä{' '}
        <strong>
          <a target='_blank' rel='noopener noreferrer' href={`https://laatulkv.fi/tietosuojaseloste`} alt=''>
            välitysliike Hämeen Laatu LKV tietosuojaseloste.
          </a>
        </strong>
      </p>
    </div>
  )
}

export default OtaYhteytta
