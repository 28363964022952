import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import StarRatings from 'react-star-ratings'
import { Formik, Field, Form } from 'formik'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import * as Yup from 'yup'
import { Button, Spinner } from 'react-bootstrap'
import logo from '../../../images/laatulkv_logo_iso.png'
import piia from '../../../images/piia-heikkilä-kuva.jpg'
import mika from '../../../images/mika-lehtonen-kuva.jpg'
import hostname from '../../../hostname'
import './PalauteOstinKodin.scss'

const PalauteOstinKodin = () => {
  const formikRef = useRef()
  const history = useHistory()
  const [rating1, setRating1] = useState()
  const [rating2, setRating2] = useState()
  const [rating3, setRating3] = useState()
  const [rating4, setRating4] = useState()
  const [rating5, setRating5] = useState()
  const [rating6, setRating6] = useState()
  // const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const submitForm = async (values) => {
    // setSubmitted(true)
    // if (!rating1 || !rating2 || !rating3 || !rating4 || !rating5 || !rating6) {
    //   return setSubmitting(false)
    // } else {
    setSubmitting(true)
    try {
      const body = {
        message1: values.message1,
        dropdown2: values.dropdown2,
        dropdown3: values.dropdown3,
        message3: values.message3,
        radio4: values.radio4,
        message4: values.message4,
        radio5: values.radio5,
        rating1: rating1,
        rating2: rating2,
        rating3: rating3,
        rating4: rating4,
        rating5: rating5,
        rating6: rating6,
        message7: values.message7,
        radio8: values.radio8,
        radio81: values.radio81,
      }
      const res = await axios.post(
        `${hostname}/laheta-palaute-ostin-kodin`,
        body
      )
      if (res.status === 200) {
        setSubmitting(false)
        let conf = window.confirm(
          'Kiitos palautteestasi! Haluaisitko arvostella meidät myös Facebookissa?'
        )
        if (conf) {
          window.scrollTo(0, 0)
          window.location.href =
            'https://www.facebook.com/laatulkv/reviews/?ref=page_internal'
        } else {
          window.scrollTo(0, 0)
          history.push('/')
        }
      }
    } catch (err) {
      setSubmitting(false)
      console.error(err)
      alert(
        'Palautteen lähettämisessä tapahtui virhe. Ole hyvä ja yritä myöhemmin uudelleen.'
      )
    }
    // }
  }

  const validationSchema = Yup.object().shape({
    message1: Yup.string().max(
      200,
      'Vastauksesi on liian pitkä, max. 200 merkkiä.'
    ),
    // dropdown2: Yup.string().required('Et ole vastannut kysymykseen 2.'),
    // dropdown3: Yup.string().required('Et ole vastannut kysymykseen 3.'),
    message3: Yup.string().max(
      200,
      'Vastauksesi on liian pitkä, max. 200 merkkiä.'
    ),
    // radio4: Yup.string().required('Et ole vastannut kysymykseen 4.'),
    message4: Yup.string().max(
      10000,
      'Vastauksesi on liian pitkä, max. 10 000 merkkiä.'
    ),
    // radio5: Yup.string().required('Et ole vastannut kysymykseen 5.'),
    message7: Yup.string().max(
      10000,
      'Vastauksesi on liian pitkä, max. 10 000 merkkiä.'
    ),
    // radio8: Yup.string().required('Et ole vastannut kysymykseen 8.'),
  })

  return (
    <div className="Asiakaspalaute-bg">
      <div className="Asiakaspalautelomake animate__animated animate__fadeIn">
        <Helmet>
          <title>Laatu LKV | Asiakaspalaute | Kiinteistönvälitys Hämeenlinna. Kiinteä ja edullinen välityspalkkio. Hyvä ja kokenut kiinteistönvälittäjä. Asunnon myynti kodistasi välittäen.</title>
        </Helmet>
        <img src={logo} alt="Laatu LKV" />
        <h1
          className="customSubTitle"
          style={{ backgroundColor: '#fff', padding: '16px 16px 12px 12px' }}
        >
          Ostin kodin
        </h1>
        <p id="ohje-teksti">
          Palautelomakkeessa on kahdeksan kysymystä ja sen täyttäminen kestää
          noin viisi minuuttia. Arvostamme palautettasi, kiitos!
        </p>
        <Formik
          innerRef={formikRef}
          initialValues={{
            message1: '',
            dropdown2: '',
            dropdown3: '',
            message3: '',
            radio4: '',
            message4: '',
            radio5: '',
            message7: '',
            radio8: '',
            radio81: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            submitForm(values)
          }}
        >
          {({ values, errors, touched, handleChange }) => (
            <Form>
              <hr />
              <h6>1. Ostetun kodin osoite?</h6>
              <Field id="message1" name="message1" placeholder="..." />
              {errors.message1 && touched.message1 ? (
                <p className="validation-error">{errors.message1}</p>
              ) : null}

              <hr />
              <h6>2. Kuka sinua palveli?</h6>

              <select
                id="dropdown2"
                name="dropdown2"
                value={values.dropdown2}
                onChange={handleChange}
                style={{ display: 'block' }}
              >
                <option value="">Valitse...</option>
                <option value="Piia">Piia Heikkilä</option>
                <option value="Mika">Mika Lehtonen</option>
              </select>
              {errors.dropdown2 && touched.dropdown2 ? (
                <p className="validation-error">{errors.dropdown2}</p>
              ) : null}

              {values.dropdown2.includes('Piia') ? (
                <img className="valittaja-kuva" src={piia} alt="Piia Heikkilä" />
              ) : null}

              {values.dropdown2.includes('Mika') ? (
                <img className="valittaja-kuva" src={mika} alt="Mika Lehtonen" />
              ) : null}

              <hr />
              <h6>3. Mitä kautta löysitte ostamanne kodin?</h6>

              <select
                id="dropdown3"
                name="dropdown3"
                value={values.dropdown3}
                onChange={handleChange}
                style={{ display: 'block' }}
              >
                <option value="">Valitse...</option>
                <option value="2">Lehti-ilmoitus</option>
                <option value="3">Internet</option>
                <option value="4">Sosiaalinen media</option>
                <option value="5">Suosittelun perusteella</option>
                <option value="6">Tapasit välittäjän</option>
                <option value="7">Vanha asiakkuus</option>
                <option value="8">Jostain muualta</option>
              </select>
              {errors.dropdown3 && touched.dropdown3 ? (
                <p className="validation-error">{errors.dropdown3}</p>
              ) : null}

              {values.dropdown3.includes('2') ? (
                <label htmlFor="message3">Missä lehdessä?</label>
              ) : null}
              {values.dropdown3.includes('3') ? (
                <label htmlFor="message3">Missä osoitteessa?</label>
              ) : null}
              {values.dropdown3.includes('4') ? (
                <label htmlFor="message3">
                  Missä sosiaalisen median palvelussa?
                </label>
              ) : null}
              {values.dropdown3.includes('5') ? (
                <label htmlFor="message3">Kuka suositteli?</label>
              ) : null}
              {values.dropdown3.includes('6') ? (
                <label htmlFor="message3">Jossain, missä?</label>
              ) : null}
              {values.dropdown3.includes('8') ? (
                <label htmlFor="message3">Mistä?</label>
              ) : null}

              {!values.dropdown3 || values.dropdown3.includes('7') ? null : (
                <Field id="message3" name="message3" placeholder="..." />
              )}
              {errors.message3 && touched.message3 ? (
                <p className="validation-error">{errors.message3}</p>
              ) : null}

              <hr />

              <h6>4. Olitteko tyytyväinen välitysliikkeen toimintaan?</h6>

              <div id="radio-group" role="group" aria-labelledby="radio-group">
                <label>
                  <Field type="radio" name="radio4" value="Kyllä" />
                  Kyllä
                </label>
                <label>
                  <Field type="radio" name="radio4" value="Ei" />
                  Ei
                </label>
              </div>
              {errors.radio4 && touched.radio4 ? (
                <p className="validation-error">{errors.radio4}</p>
              ) : null}

              {values.radio4.includes('Kyllä') ? (
                <label htmlFor="message4">Miksi olitte tyytyväinen?</label>
              ) : null}
              {values.radio4.includes('Ei') ? (
                <label htmlFor="message4">Miksi olitte tyytymätön?</label>
              ) : null}
              {values.radio4.includes('Kyllä') ||
              values.radio4.includes('Ei') ? (
                <Field
                  as="textarea"
                  rows="5"
                  id="message4"
                  name="message4"
                  placeholder="..."
                />
              ) : null}
              {errors.message4 && touched.message4 ? (
                <p className="validation-error">{errors.message4}</p>
              ) : null}

              <hr />
              <h6>5. Olisitteko valmis suosittelemaan välitysliikettä?</h6>
              <div id="radio-group" role="group" aria-labelledby="radio-group">
                <label>
                  <Field type="radio" name="radio5" value="Kyllä" />
                  Kyllä
                </label>
                <label>
                  <Field type="radio" name="radio5" value="Ei" />
                  Ei
                </label>
              </div>
              {errors.radio5 && touched.radio5 ? (
                <p className="validation-error">{errors.radio5}</p>
              ) : null}

              <hr />
              <h6>
                6. Minkä arvosanan antaisitte välitysliikkeen toiminnalle?
              </h6>
              <p id="ohje-teksti">1 = välttävä, 5 = erinomainen</p>

              <ul>
                <li>Palvelu</li>
                <StarRatings
                  rating={rating1}
                  starRatedColor="#fecbb8"
                  changeRating={setRating1}
                  numberOfStars={5}
                  name="rating1"
                  starHoverColor="#E2B09E"
                />
                {/* {submitted && !rating1 ? (
                  <p className="validation-error">
                    Et ole antanut arvosanaa Palvelulle.
                  </p>
                ) : null} */}
                <li>Yhteydenpito</li>
                <StarRatings
                  rating={rating2}
                  starRatedColor="#fecbb8"
                  changeRating={setRating2}
                  numberOfStars={5}
                  name="rating2"
                  starHoverColor="#E2B09E"
                />
                {/* {submitted && !rating2 ? (
                  <p className="validation-error">
                    Et ole antanut arvosanaa Yhteydenpidolle.
                  </p>
                ) : null} */}
                <li>Asiantuntemus</li>
                <StarRatings
                  rating={rating3}
                  starRatedColor="#fecbb8"
                  changeRating={setRating3}
                  numberOfStars={5}
                  name="rating3"
                  starHoverColor="#E2B09E"
                />
                {/* {submitted && !rating3 ? (
                  <p className="validation-error">
                    Et ole antanut arvosanaa Asiantuntemukselle.
                  </p>
                ) : null} */}
                <li>Myynti-ilmoitus</li>
                <StarRatings
                  rating={rating4}
                  starRatedColor="#fecbb8"
                  changeRating={setRating4}
                  numberOfStars={5}
                  name="rating4"
                  starHoverColor="#E2B09E"
                />
                {/* {submitted && !rating4 ? (
                  <p className="validation-error">
                    Et ole antanut arvosanaa Myynti-ilmoitukselle.
                  </p>
                ) : null} */}
                <li>Esittelytilanteen sujuvuus</li>
                <StarRatings
                  rating={rating5}
                  starRatedColor="#fecbb8"
                  changeRating={setRating5}
                  numberOfStars={5}
                  name="rating5"
                  starHoverColor="#E2B09E"
                />
                {/* {submitted && !rating5 ? (
                  <p className="validation-error">
                    Et ole antanut arvosanaa Esittelytilanteen sujuvuudelle.
                  </p>
                ) : null} */}
                <li>Käytännön järjestelyt</li>
                <StarRatings
                  rating={rating6}
                  starRatedColor="#fecbb8"
                  changeRating={setRating6}
                  numberOfStars={5}
                  name="rating6"
                  starHoverColor="#E2B09E"
                />
                {/* {submitted && !rating6 ? (
                  <p className="validation-error">
                    Et ole antanut arvosanaa Käytännön järjestelyille.
                  </p>
                ) : null} */}
              </ul>

              <hr />
              <h6>7. Vapaat terveiset Laatu LKV:lle.</h6>
              <Field
                as="textarea"
                rows="5"
                id="message7"
                name="message7"
                placeholder="..."
              />
              {errors.message7 && touched.message7 ? (
                <p className="validation-error">{errors.message7}</p>
              ) : null}

              <hr />
              <h6>
                8. Saako palautteenne julkaista nimettömänä Hämeen Laatu LKV:n
                markkinoinnissa?
              </h6>
              <div id="radio-group" role="group" aria-labelledby="radio-group">
                <label>
                  <Field type="radio" name="radio8" value="Kyllä" />
                  Kyllä
                </label>
                <label>
                  <Field type="radio" name="radio8" value="Ei" />
                  Ei
                </label>
              </div>
              {errors.radio8 && touched.radio8 ? (
                <p className="validation-error">{errors.radio8}</p>
              ) : null}

              {values.radio8.includes('Kyllä') ? (
                <>
                  <h6>
                    Saako palautteen yhteyteen liittää yhden asunnon kuvista?
                  </h6>
                  <div
                    id="radio-group"
                    role="group"
                    aria-labelledby="radio-group"
                  >
                    <label>
                      <Field type="radio" name="radio81" value="Kyllä" />
                      Kyllä
                    </label>
                    <label>
                      <Field type="radio" name="radio81" value="Ei" />
                      Ei
                    </label>
                  </div>
                </>
              ) : null}

              <Button type="submit" variant="laatulkv" disabled={submitting}>
                {submitting ? (
                  <Spinner animation="border" variant="light" />
                ) : (
                  'Lähetä palaute'
                )}
              </Button>
              {(errors.message1 && touched.message1) ||
              (errors.message3 && touched.message3) ||
              (errors.message4 && touched.message4) ||
              (errors.message7 && touched.message7) ||
              (errors.dropdown2 && touched.dropdown2) ||
              (errors.dropdown3 && touched.dropdown3) ||
              (errors.radio4 && touched.radio4) ||
              (errors.radio5 && touched.radio5) ||
              (errors.radio8 && touched.radio8) ? (
                // (!rating1 && submitted) ||
                // (!rating2 && submitted) ||
                // (!rating3 && submitted) ||
                // (!rating4 && submitted)
                <p className="validation-error">
                  Lomakkeessa on virheitä. Ole hyvä ja tarkista vastauksesi.
                </p>
              ) : (
                <hr />
              )}

              <hr />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default PalauteOstinKodin
