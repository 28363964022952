import './Landing.scss'
import { useSelector } from 'react-redux'

const Landing = () => {
  const loading = useSelector((state) => state.general.loading)

  return (
    <div
      className="Landing animate__animated animate__fadeInUp"
      id="Landing"
      hidden={loading}
    >
      <div className="animatedLogo">
        <div className="animate__animated animate__fadeIn animate__delay-1s">
          <h1>Laatu LKV</h1>
        </div>
        <h2 className="animate__animated animate__fadeIn animate__delay-2s">
          KODISTASI VÄLITTÄEN
        </h2>
      </div>
    </div>
  )
}

export default Landing
