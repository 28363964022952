import { useEffect, useState } from "react"
import './EmbedVideo.scss'

const EmbedVideo = (props) => {
  const { videoUrl } = props
  const [embedUrl, setEmbedUrl] = useState('')

  function convertToEmbedUrl(shortUrl) {
    if (shortUrl.includes("youtu.be")) {
      const videoId = shortUrl.split("/").pop()
      const embedUrl = `https://www.youtube.com/embed/${videoId}`
      return embedUrl
    } else {
      return ''
    }
  }

  useEffect(() => {
    setEmbedUrl(convertToEmbedUrl(videoUrl))
  }, [videoUrl])

  return (
    embedUrl ?
        <iframe
        class="embed-video"
        width="100%"
        height="400"
        style={{ margin: '48px auto' }}
        src={embedUrl}
        title="Esittelyvideo"
        frameBorder="0"
        allow="accelerometer;clipboard-write;encrypted-media;gyroscope;picture-in-picture;web-share"
        allowFullScreen />
        : <></>
  )
}

export default EmbedVideo
