import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import { BsCheckCircle } from 'react-icons/bs'
import ModalGDPR from './Modal'
import './index.scss'

const GDPR = (props) => {

  const { cookieConsent, updateCookieConsent, cookieModalVisible, setCookieModalVisible } = props

  return (
    <>
    <ModalGDPR visible={cookieModalVisible} setVisible={setCookieModalVisible} cookieConsent={cookieConsent} updateCookieConsent={updateCookieConsent} cookieModalVisible={cookieModalVisible} setCookieModalVisible={setCookieModalVisible} />
      {cookieConsent !== 'all' && cookieConsent !== 'minimum' ? <div className='accept-cookie-policy'>
        <span className='cookie-info'>
          Sivusto käyttää evästeitä paremman käyttökokemuksen tarjoamiseksi. 
          Lue lisää evästeiden käytöstä ja tietojen käsittelystä <Link to={'/evastekaytanto'}>täältä</Link>.
        </span>
        <Button
        variant='link'
        style={{ background: 'transparent', color: '#007bff' }}
        onClick={() => setCookieModalVisible(true)}
        >
          Evästeasetukset
        </Button>
        <Button
          variant='light'
          onClick={() => updateCookieConsent('all')}
          style={{ background: '#fecbb8', color: '#000' }}>
          <span style={{ marginRight: '0.6rem' }}>Hyväksyn kaikki</span>
          <BsCheckCircle />
        </Button>
      </div> : null}
    </>
  )
}

export default GDPR
