import { useState } from 'react'

export const useCookie = (key, defaultValue) => {
    const getCookie = () => getItem(key) || defaultValue
    const [cookie, setCookie] = useState(getCookie())
    const updateCookie = (value) => {
        setCookie(value)
        setItem(key, value)
    }
    return [cookie, updateCookie]
}

export const getItem = (key) => {
  const decodedCookies = decodeURIComponent(document.cookie)
  const cookieArray = decodedCookies.split(';')
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i]
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1)
    }
    if (cookie.indexOf(key) === 0) {
      return cookie.substring(key.length, cookie.length)
    }
  }
  return ''
}

export const setItem = (key, newValue) => {
    const d = new Date()
    d.setTime(d.getTime() + (365*24*60*60*1000))
    const expirationDate = d.toUTCString()
    document.cookie = `${key}${newValue};expires=${expirationDate};`
}
