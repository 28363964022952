// React
import { useState, useRef, useEffect } from 'react'

// React Router Dom
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Styling & Utilities
import { Button } from 'react-bootstrap'
import bars from '../../images/bars-solid.svg'
import fb from '../../images/facebook-brands-white.svg'
import instagram from '../../images/instagram-brands-white.svg'
import './NavBarMobile.scss'

const NavbarMobile = () => {
  const location = useLocation()
  const history = useHistory()

  const loading = useSelector((state) => state.general.loading)
  const [menuOpen, toggleMenu] = useState(false)

  const goToLanding = () => {
    if (location.pathname === '/') {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
      toggleMenu(false)
    } else {
      history.push('/')
      window.scrollTo(0, 0)
      toggleMenu(false)
    }
  }

  const checkAndClose = () => {
    if (!menuOpen) {
      toggleMenu(false)
    }
  }

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.target.id === 'barsIcon' ||
            event.target.id === 'toggleMenu'
          ) {
          } else {
            checkAndClose()
          }
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
    <>
      <div
        className="NavBarMobile"
        id="NavBarMobile"
        hidden={
          (loading && location.pathname === '/') ||
          (loading && location.pathname === '') ||
          location.pathname === '/palaute' ||
          location.pathname === '/palaute/' ||
          location.pathname === '/palaute-ostin-kodin' ||
          location.pathname === '/palaute-ostin-kodin/' ||
          location.pathname === '/palaute-myin-kodin' ||
          location.pathname === '/palaute-myin-kodin/'
        }
      >
        <h4 className="customSubTitleNavBar">Laatu LKV</h4>
        <Button
          variant="laatulkv"
          id="toggleMenu"
          onClick={() => toggleMenu(!menuOpen)}
        >
          <img src={bars} id="barsIcon" alt="Hampurilaisvalikko" />
        </Button>
      </div>
      <nav
        className="MenuMobile"
        id="MenuMobile"
        ref={wrapperRef}
        style={
          location.pathname === '/' || location.pathname === ''
            ? { marginTop: '0' }
            : {}
        }
        hidden={menuOpen ? false : true}
      >
        <Link to="/" onClick={goToLanding}>
          ETUSIVU
        </Link>
        <Link to="/laatulkv" onClick={() => toggleMenu(false)}>
          LAATU LKV
        </Link>
        <Link to="/palvelut" onClick={() => toggleMenu(false)}>
          PALVELUT
        </Link>
        <Link to="/kodit?filter=10000000000" onClick={() => toggleMenu(false)}>
          KODIT
        </Link>
        <Link to="/ota-yhteytta" onClick={() => toggleMenu(false)}>
          OTA YHTEYTTÄ
        </Link>
        <Link to="/yhteistyossa" onClick={() => toggleMenu(false)}>
          YHTEISTYÖSSÄ
        </Link>

        <div className="socialMediaMobile">
          <Button
            variant="laatulkv"
            id="fb"
            onClick={() =>
              window.location.assign('https://www.facebook.com/laatulkv/')
            }
          >
            <img src={fb} alt="Facebook logo" />
          </Button>

          <Button
            variant="laatulkv"
            id="ig"
            onClick={() =>
              window.location.assign(
                'https://www.instagram.com/laatulkv/?hl=fi'
              )
            }
          >
            <img src={instagram} alt="Instagram logo" />
          </Button>
        </div>
      </nav>
    </>
  )
}

export default NavbarMobile
