import { useEffect, useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import axios from 'axios'
import './VuokraAsuntoHakemusUusi.scss'
import hostname from '../../hostname'

const VuokraAsuntoHakemusUusi = () => {
  const getUrlParams = () => {
    const search = window.location.search
    const params = new URLSearchParams(search)
    return {
      a: params.get('a') || '',
      b: params.get('b') || '',
      c: params.get('c') || ''
    }
  }

  useEffect(() => {
    const params = getUrlParams()
    const kohdeString = `${params.a}, ${params.b}, ${params.c}`
    if (kohdeString && kohdeString.length > 5) {
      setKohde(kohdeString)
    }
  }, [])

  const [submitPending, setSubmitPending] = useState(false)

  // Kohteen tiedot
  const [kohde, setKohde] = useState('')

  // Hakijan tiedot
  const [nimi, setNimi] = useState('')
  const [osoite, setOsoite] = useState('')
  const [puhelin, setPuhelin] = useState('')
  const [sposti, setSposti] = useState('')
  const [hetu, setHetu] = useState('')
  const [arvo, setArvo] = useState(undefined)
  const [tyonantaja, setTyonantaja] = useState('')
  const [ammatti, setAmmatti] = useState('')
  const [kesto, setKesto] = useState('')
  const [tyosuhde, setTyosuhde] = useState(undefined)
  const [opiskelupaikka, setOpiskelupaikka] = useState('')
  const [tulot, setTulot] = useState('')
  const [tupakointi, setTupakointi] = useState(undefined)
  const [lemmikki, setLemmikki] = useState(undefined)
  const [lemmikkiText, setLemmikkiText] = useState('')

  // Hakijan mukana muuttavan puolison tiedot
  const [hakija2, setHakija2] = useState(undefined)
  const [nimi2, setNimi2] = useState('')
  const [osoite2, setOsoite2] = useState('')
  const [puhelin2, setPuhelin2] = useState('')
  const [sposti2, setSposti2] = useState('')
  const [hetu2, setHetu2] = useState('')
  const [arvo2, setArvo2] = useState(undefined)
  const [tyonantaja2, setTyonantaja2] = useState('')
  const [ammatti2, setAmmatti2] = useState('')
  const [kesto2, setKesto2] = useState('')
  const [tyosuhde2, setTyosuhde2] = useState(undefined)
  const [opiskelupaikka2, setOpiskelupaikka2] = useState('')
  const [tulot2, setTulot2] = useState('')

  // Muiden mukana muuttavien tiedot
  const [nimi3, setNimi3] = useState('')
  const [syntyma3, setSyntyma3] = useState('')
  const [nimi4, setNimi4] = useState('')
  const [syntyma4, setSyntyma4] = useState('')
  const [nimi5, setNimi5] = useState('')
  const [syntyma5, setSyntyma5] = useState('')

  // Tiedot haettavasta huoneistosta
  const [alkamispvm, setAlkamispvm] = useState('')

  // Käytettävissä oleva vuokravakuus
  const [vakuus, setVakuus] = useState([])

  // Lisätietoja
  const [lisatietoja, setLisatietoja] = useState('')

  const submitForm = async () => {
    if (
      kohde === '' ||
      nimi === '' ||
      osoite === '' ||
      puhelin === '' ||
      sposti === '' ||
      hetu === '' ||
      tulot === '' ||
      alkamispvm === '' ||
      arvo === undefined ||
      tupakointi === undefined ||
      lemmikki === undefined ||
      vakuus.length === 0
    ) {
      return window.alert('Pakollisia tietoja puuttuu. Ole hyvä ja tarkista tiedot.')
    }

    if (arvo === 'Työssäkäyvä') {
      if (tyonantaja === '' || ammatti === '' || kesto === '' || tyosuhde === undefined) {
        return window.alert('Pakollisia tietoja puuttuu. Ole hyvä ja tarkista tiedot.')
      }
    }

    if (arvo2 === 'Työssäkäyvä') {
      if (tyonantaja2 === '' || ammatti2 === '' || kesto2 === '' || tyosuhde2 === undefined) {
        return window.alert('Pakollisia tietoja puuttuu. Ole hyvä ja tarkista tiedot.')
      }
    }

    if (arvo === 'Opiskelija') {
      if (opiskelupaikka === '') {
        return window.alert('Pakollisia tietoja puuttuu. Ole hyvä ja tarkista tiedot.')
      }
    }

    if (arvo2 === 'Opiskelija') {
      if (opiskelupaikka2 === '') {
        return window.alert('Pakollisia tietoja puuttuu. Ole hyvä ja tarkista tiedot.')
      }
    }

    setSubmitPending(true)
    const body = {
      kohde: kohde,
      nimi: nimi,
      osoite: osoite,
      puhelin: puhelin,
      sposti: sposti,
      hetu: hetu,
      arvo: arvo,
      tyonantaja: tyonantaja,
      ammatti: ammatti,
      kesto: kesto,
      tyosuhde: tyosuhde === undefined ? '' : tyosuhde,
      opiskelupaikka: opiskelupaikka,
      tulot: tulot,
      tupakointi: tupakointi,
      lemmikki: lemmikki,
      lemmikkiText: lemmikkiText,
      hakija2: hakija2 === undefined ? '' : hakija2,
      nimi2: nimi2,
      osoite2: osoite2,
      puhelin2: puhelin2,
      sposti2: sposti2,
      hetu2: hetu2,
      arvo2: arvo2 === undefined ? '' : arvo2,
      tyonantaja2: tyonantaja2,
      ammatti2: ammatti2,
      kesto2: kesto2,
      tyosuhde2: tyosuhde2 === undefined ? '' : tyosuhde2,
      opiskelupaikka2: opiskelupaikka2,
      tulot2: tulot2,
      nimi3: nimi3,
      syntyma3: syntyma3,
      nimi4: nimi4,
      syntyma4: syntyma4,
      nimi5: nimi5,
      syntyma5: syntyma5,
      alkamispvm: alkamispvm,
      vakuus: vakuus,
      lisatietoja: lisatietoja,
    }

    try {
      const res = await axios.post(`${hostname}/laheta-vuokra-asuntohakemus`, body)
      if (res.status === 200) {
        setSubmitPending(false)
        alert('Vuokra-asuntohakemus vastaanotettu. Olemme teihin yhteydessä mahdollisimman pian.')
        window.scrollTo(0, 0)
        window.location.reload()
      }
    } catch (err) {
      console.error(err)
    }
  }

  const determineVakuus = (value) => {
    if (!vakuus.includes(value)) {
      setVakuus([...vakuus, value])
    } else {
      let newArray = [...vakuus]
      let i = newArray.indexOf(value)
      newArray.splice(i, 1)
      setVakuus(newArray)
    }
  }

  return (
    <div className='VuokraAsuntoHakemusUusi'>
        <p style={{ margin: 'auto auto 32px auto', textAlign: 'center', fontSize: '14px' }}>
          Tähdellä (*) merkityt kentät ovat pakollisia.
        </p>
        <Form>
          <h5>KOHTEEN TIEDOT</h5>
          <Form.Group>
            <Form.Label>Vuokrattavan kohteen osoite *</Form.Label>
            <Form.Control name='kohde' type='text' value={kohde} onChange={(e) => setKohde(e.target.value)} />
          </Form.Group>
          <h5>HAKIJAN TIEDOT</h5>
          <Form.Group>
            <Form.Label>Nimi *</Form.Label>
            <Form.Control name='nimi' type='text' value={nimi} onChange={(e) => setNimi(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Osoite ja paikkakunta *</Form.Label>
            <Form.Control name='osoite' type='text' value={osoite} onChange={(e) => setOsoite(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Puhelin *</Form.Label>
            <Form.Control name='puhelin' type='text' value={puhelin} onChange={(e) => setPuhelin(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Sähköposti *</Form.Label>
            <Form.Control name='sposti' type='text' value={sposti} onChange={(e) => setSposti(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Henkilötunnus / Yritystunnus *</Form.Label>
            <Form.Control name='hetu' type='text' value={hetu} onChange={(e) => setHetu(e.target.value)} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Arvo *</Form.Label>
            <Form.Check
              name='arvo'
              type='radio'
              label='Työssäkäyvä'
              defaultChecked={arvo === 'Työssäkäyvä'}
              value={arvo === 'Työssäkäyvä'}
              onChange={() => setArvo('Työssäkäyvä')}
            />
            <Form.Check
              name='arvo'
              type='radio'
              label='Opiskelija'
              defaultChecked={arvo === 'Opiskelija'}
              value={arvo === 'Opiskelija'}
              onChange={() => setArvo('Opiskelija')}
            />
            <Form.Check
              name='arvo'
              type='radio'
              label='Eläkeläinen'
              defaultChecked={arvo === 'Eläkeläinen'}
              value={arvo === 'Eläkeläinen'}
              onChange={() => setArvo('Eläkeläinen')}
            />
            <Form.Check
              name='arvo'
              type='radio'
              label='Työtön'
              defaultChecked={arvo === 'Työtön'}
              value={arvo === 'Työtön'}
              onChange={() => setArvo('Työtön')}
            />
          </Form.Group>

          <Form.Group hidden={arvo !== 'Työssäkäyvä'}>
            <Form.Label>Työnantajan nimi, osoite ja puhelin *</Form.Label>
            <Form.Control
              name='tyonantaja'
              type='text'
              value={tyonantaja}
              onChange={(e) => setTyonantaja(e.target.value)}
            />
          </Form.Group>

          <Form.Group hidden={arvo !== 'Työssäkäyvä'}>
            <Form.Label>Ammatti *</Form.Label>
            <Form.Control name='ammatti' type='text' value={ammatti} onChange={(e) => setAmmatti(e.target.value)} />
          </Form.Group>

          <Form.Group hidden={arvo !== 'Työssäkäyvä'}>
            <Form.Label>Työsuhde alkanut / kesto *</Form.Label>
            <Form.Control name='kesto' type='text' value={kesto} onChange={(e) => setKesto(e.target.value)} />
          </Form.Group>

          <Form.Group hidden={arvo !== 'Työssäkäyvä'}>
            <Form.Label>Työsuhde *</Form.Label>
            <Form.Check
              name='tyosuhde'
              type='radio'
              label='Määräaikainen'
              defaultChecked={tyosuhde === 'Määräaikainen'}
              value={tyosuhde === 'Määräaikainen'}
              onChange={() => setTyosuhde('Määräaikainen')}
            />
            <Form.Check
              name='tyosuhde'
              type='radio'
              label='Vakituinen'
              defaultChecked={tyosuhde === 'Vakituinen'}
              value={tyosuhde === 'Vakituinen'}
              onChange={() => setTyosuhde('Vakituinen')}
            />
          </Form.Group>

          <Form.Group hidden={arvo !== 'Opiskelija'}>
            <Form.Label>Opiskelupaikka *</Form.Label>
            <Form.Control
              name='opiskelupaikka'
              type='text'
              value={opiskelupaikka}
              onChange={(e) => setOpiskelupaikka(e.target.value)}
            />
          </Form.Group>

          <Form.Group hidden={arvo !== 'Opiskelija'}></Form.Group>

          <Form.Group>
            <Form.Label>Tulot euro / kk * (**)</Form.Label>
            <Form.Control name='tulot' type='text' value={tulot} onChange={(e) => setTulot(e.target.value)} />
          </Form.Group>

          <Form.Group>
            <p style={{ fontSize: '12px' }}>
              ** Mikäli hakemus johtaa vuokrasopimuksen solmimiseen hakijan ja vuokranantajan välillä, on hakijan vielä
              ennen lopullisen vuokrasopimuksen allekirjoittamista esitettävä selvitys tuloistaan, esim. palkkatodistus.
            </p>
          </Form.Group>

          <Form.Group>
            <Form.Label>Joku huoneistoon muuttavista tupakoi *</Form.Label>
            <Form.Check
              name='tupakointi'
              type='radio'
              label='Ei'
              defaultChecked={tupakointi === 'Ei'}
              value={tupakointi === 'Ei'}
              onChange={() => setTupakointi('Ei')}
            />
            <Form.Check
              name='tupakointi'
              type='radio'
              label='Kyllä'
              defaultChecked={tupakointi === 'Kyllä'}
              value={tupakointi === 'Kyllä'}
              onChange={() => setTupakointi('Kyllä')}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Lemmikkieläimiä *</Form.Label>
            <Form.Check
              name='lemmikki'
              type='radio'
              label='Ei'
              defaultChecked={lemmikki === 'Ei'}
              value={lemmikki === 'Ei'}
              onChange={() => setLemmikki('Ei')}
            />
            <Form.Check
              name='lemmikki'
              type='radio'
              label='Kyllä; mitä ja minkä ikäisiä'
              defaultChecked={lemmikki === 'Kyllä'}
              value={lemmikki === 'Kyllä'}
              onChange={() => setLemmikki('Kyllä')}
            />
            <Form.Control
              name='lemmikkiText'
              type='text'
              value={lemmikkiText}
              onChange={(e) => setLemmikkiText(e.target.value)}
            />
          </Form.Group>

          <h5>HAKIJAN / MUKANA MUUTTAVAN PUOLISON TIEDOT</h5>
          <Form.Group
            style={{
              width: '100%',
              display: 'inline-flex',
              justifyContent: 'center',
            }}>
            <Form.Check
              name='hakija2'
              type='radio'
              label='Hakija'
              defaultChecked={hakija2 === 'Hakija'}
              value={hakija2 === 'Hakija'}
              onChange={() => setHakija2('Hakija')}
            />
            <Form.Check
              name='hakija2'
              type='radio'
              label='Mukana muuttava puoliso'
              style={{ marginLeft: '30px' }}
              defaultChecked={hakija2 === 'Mukana muuttava puoliso'}
              value={hakija2 === 'Mukana muuttava puoliso'}
              onChange={() => setHakija2('Mukana muuttava puoliso')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Nimi</Form.Label>
            <Form.Control name='nimi2' type='text' value={nimi2} onChange={(e) => setNimi2(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Osoite ja paikkakunta</Form.Label>
            <Form.Control name='osoite2' type='text' value={osoite2} onChange={(e) => setOsoite2(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Puhelin</Form.Label>
            <Form.Control name='puhelin2' type='text' value={puhelin2} onChange={(e) => setPuhelin2(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Sähköposti</Form.Label>
            <Form.Control name='sposti2' type='text' value={sposti2} onChange={(e) => setSposti2(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Henkilötunnus / Yritystunnus</Form.Label>
            <Form.Control name='hetu2' type='text' value={hetu2} onChange={(e) => setHetu2(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Arvo</Form.Label>
            <Form.Check
              name='arvo2'
              type='radio'
              label='Työssäkäyvä'
              defaultChecked={arvo2 === 'Työssäkäyvä'}
              value={arvo2 === 'Työssäkäyvä'}
              onChange={() => setArvo2('Työssäkäyvä')}
            />
            <Form.Check
              name='arvo2'
              type='radio'
              label='Opiskelija'
              defaultChecked={arvo2 === 'Opiskelija'}
              value={arvo2 === 'Opiskelija'}
              onChange={() => setArvo2('Opiskelija')}
            />
            <Form.Check
              name='arvo2'
              type='radio'
              label='Eläkeläinen'
              defaultChecked={arvo2 === 'Eläkeläinen'}
              value={arvo2 === 'Eläkeläinen'}
              onChange={() => setArvo2('Eläkeläinen')}
            />
            <Form.Check
              name='arvo2'
              type='radio'
              label='Työtön'
              defaultChecked={arvo2 === 'Työtön'}
              value={arvo2 === 'Työtön'}
              onChange={() => setArvo2('Työtön')}
            />
          </Form.Group>

          <Form.Group hidden={arvo2 !== 'Työssäkäyvä'}>
            <Form.Label>Työnantajan nimi, osoite ja puhelin</Form.Label>
            <Form.Control
              name='tyonantaja2'
              type='text'
              value={tyonantaja2}
              onChange={(e) => setTyonantaja2(e.target.value)}
            />
          </Form.Group>

          <Form.Group hidden={arvo2 !== 'Työssäkäyvä'}>
            <Form.Label>Ammatti</Form.Label>
            <Form.Control name='ammatti2' type='text' value={ammatti2} onChange={(e) => setAmmatti2(e.target.value)} />
          </Form.Group>

          <Form.Group hidden={arvo2 !== 'Työssäkäyvä'}>
            <Form.Label>Työsuhde alkanut / kesto</Form.Label>
            <Form.Control name='kesto2' type='text' value={kesto2} onChange={(e) => setKesto2(e.target.value)} />
          </Form.Group>

          <Form.Group hidden={arvo2 !== 'Työssäkäyvä'}>
            <Form.Label>Työsuhde</Form.Label>
            <Form.Check
              name='tyosuhde2'
              type='radio'
              label='Määräaikainen'
              defaultChecked={tyosuhde2 === 'Määräaikainen'}
              value={tyosuhde2 === 'Määräaikainen'}
              onChange={() => setTyosuhde2('Määräaikainen')}
            />
            <Form.Check
              name='tyosuhde2'
              type='radio'
              label='Vakituinen'
              defaultChecked={tyosuhde2 === 'Vakituinen'}
              value={tyosuhde2 === 'Vakituinen'}
              onChange={() => setTyosuhde2('Vakituinen')}
            />
          </Form.Group>

          <Form.Group hidden={arvo2 !== 'Opiskelija'}>
            <Form.Label>Opiskelupaikka *</Form.Label>
            <Form.Control
              name='opiskelupaikka2'
              type='text'
              value={opiskelupaikka2}
              onChange={(e) => setOpiskelupaikka2(e.target.value)}
            />
          </Form.Group>

          <Form.Group hidden={arvo2 !== 'Opiskelija'}></Form.Group>

          <Form.Group>
            <Form.Label>Tulot euro / kk **</Form.Label>
            <Form.Control name='tulot2' type='text' value={tulot2} onChange={(e) => setTulot2(e.target.value)} />
          </Form.Group>

          <Form.Group>
            <p style={{ fontSize: '12px' }}>
              ** Mikäli hakemus johtaa vuokrasopimuksen solmimiseen hakijan ja vuokranantajan välillä, on hakijan vielä
              ennen lopullisen vuokrasopimuksen allekirjoittamista esitettävä selvitys tuloistaan, esim. palkkatodistus.
            </p>
          </Form.Group>

          <h5>MUIDEN MUKANA MUUTTAVIEN TIEDOT</h5>

          <Form.Group>
            <Form.Label>Nimi</Form.Label>
            <Form.Control name='nimi3' type='text' value={nimi3} onChange={(e) => setNimi3(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Syntymävuosi</Form.Label>
            <Form.Control name='syntyma3' type='text' value={syntyma3} onChange={(e) => setSyntyma3(e.target.value)} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Nimi</Form.Label>
            <Form.Control name='nimi4' type='text' value={nimi4} onChange={(e) => setNimi4(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Syntymävuosi</Form.Label>
            <Form.Control name='syntyma4' type='text' value={syntyma4} onChange={(e) => setSyntyma4(e.target.value)} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Nimi</Form.Label>
            <Form.Control name='nimi5' type='text' value={nimi5} onChange={(e) => setNimi5(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Syntymävuosi</Form.Label>
            <Form.Control name='syntyma5' type='text' value={syntyma5} onChange={(e) => setSyntyma5(e.target.value)} />
          </Form.Group>

          <h5>TIEDOT HAETTAVASTA HUONEISTOSTA</h5>
          <Form.Group>
            <Form.Label>Vuokrasuhteen toivottu alkamispvm. *</Form.Label>
            <Form.Control
              name='alkamispvm'
              type='text'
              value={alkamispvm}
              onChange={(e) => setAlkamispvm(e.target.value)}
            />
          </Form.Group>

          <h5>KÄYTETTÄVISSÄ OLEVA VUOKRAVAKUUS *</h5>
          <Form.Group
            className='vakuusRadioButtons'
            style={{
              width: '100%',
              display: 'inline-flex',
              justifyContent: 'center',
            }}>
            <Form.Check
              name='vakuus'
              type='checkbox'
              label='Vuokravakuustili'
              defaultChecked={vakuus === 'Vuokravakuustili'}
              // value={vakuus === 'Vuokravakuustili'}
              onChange={() => determineVakuus('Vuokravakuustili')}
            />
            <Form.Check
              name='vakuus'
              type='checkbox'
              label='Tilisiirto vuokranantajan tilille'
              style={{ marginLeft: '30px' }}
              onChange={() => determineVakuus('Tilisiirto vuokraantajan tilille')}
            />
            <Form.Check
              name='vakuus'
              type='checkbox'
              label='Kelan maksusitoumus'
              style={{ marginLeft: '30px' }}
              key=''
              onChange={() => determineVakuus('Kelan maksusitoumus')}
            />
          </Form.Group>

          <h5>LISÄTIETOJA</h5>
          <Form.Group style={{ width: '100%' }}>
            <Form.Label>Kerrothan jotain itsestäsi / teistä</Form.Label>
            <Form.Control
              name='lisatietoja'
              type='text'
              as='textarea'
              rows='6'
              value={lisatietoja}
              onChange={(e) => setLisatietoja(e.target.value)}
            />
          </Form.Group>

          <h5>LUOTTOTIETOJEN TARKISTAMINEN</h5>
          <Form.Group style={{ width: '100%' }}>
            <p style={{ fontSize: '14px' }}>
              Lähettämällä lomakkeen annan suostumukseni luottotietojen tarkistamiseen ja tietojen luovuttamiseen
              vuokranantajalle.
            </p>
          </Form.Group>

          <h5>TIETOJEN REKISTERÖIMINEN, SÄILYTTÄMINEN JA LUOVUTTAMINEN</h5>
          <Form.Group style={{ width: '100%' }}>
            <p style={{ fontSize: '14px' }}>
              Välitysliike voi säilyttää tämän vuokra-asuntohakemuksen ja sen liitteenä mahdollisesti olevat tiedot
              tulevia asuinhuoneiston vuokraustilanteita varten 3 kk ajan, vaikka tämä hakemus ei johtaisikaan
              vuokrasopimukseen vuokralaisen ja vuokranantajan välillä. Annan / annamme luvan siihen, että välitysliike
              saa luovuttaa tässä hakemuksessa annetut tiedot, mahdolliset liitteet, tai niissä annetut tiedot
              vuokranantajille tai mahdollisille tuleville vuokranantajille. Henkilötietojen käsittelyssä ja tietojen
              hankkimisessa noudatetaan henkilötietolakia (523/1999) ja lakia kiinteistönvälitys- liikkeistä ja
              vuokrahuoneiston välitysliikkeistä (1075/2000).
            </p>
            <p style={{ fontSize: '14px' }}>
              Lähettämällä lomakkeen annan suostumukseni siihen, että välitysliike lähettää minulle ja vuokrasopimuksen
              toiselle osapuolelle sähköpostitse henkilötunnukseni sisältäviä asiakirjoja.
            </p>
          </Form.Group>
        </Form>
        <Button style={{ margin: '0 auto', width: '180px', padding: '10px 15px' }} variant='laatulkv' onClick={submitForm} disabled={submitPending}>
          {submitPending ? <Spinner animation='border' size='sm' variant='dark' /> : 'Lähetä'}
        </Button>
    </div>
  )
}

export default VuokraAsuntoHakemusUusi
