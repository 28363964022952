import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { actions } from '../../redux/actions'
import './Asetukset.scss'

const Asetukset = () => {
  const isAuthenticated = useSelector((state) => state.general.isAuthenticated)
  const sold = useSelector((state) => state.general.sold)
  const fbPosts = useSelector((state) => state.general.fbPosts)
  const updateDone = useSelector((state) => state.general.updateDone)
  const history = useHistory()
  const dispatch = useDispatch()

  const [appState, setAppState] = useState({
    lkm: '',
    fbposts: '',
  })
  const { lkm, fbposts } = appState

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/kirjaudu')
    }
    if (isAuthenticated && sold && fbPosts) {
      setAppState({
        lkm: sold,
        fbposts: fbPosts.join(',\n'),
      })
    }
    // eslint-disable-next-line
  }, [isAuthenticated, sold, fbPosts])

  useEffect(() => {
    return function clearLocalStorage() {
      localStorage.removeItem('laatulkv-token')
      dispatch(actions.checkAuth())
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (updateDone) {
      window.alert(
        'Tiedot tallennettu onnistuneesti. Muista tarkistaa sivustolta päivittämällä sivu uudeelleen, että syöttämäsi tiedot menivät oikein. Ongelmatilanteissa ota yhteys ville@wilcode.fi.'
      )
    }
  }, [updateDone])

  const onChange = (e) =>
    setAppState({ ...appState, [e.target.name]: e.target.value })

  const onSubmit = async (e) => {
    e.preventDefault()
    dispatch(actions.updateData(lkm, fbposts))
  }

  return (
    <div className="Asetukset animate__animated animate__fadeIn">
      <h1 className="customSubTitle">Asetukset</h1>
      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>Välitetyt asunnot lukumäärä</Form.Label>
          <Form.Control
            name="lkm"
            type="number"
            value={lkm}
            onChange={onChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Facebook postaukset</Form.Label>
          <Form.Control
            name="fbposts"
            type="text"
            as="textarea"
            rows="9"
            value={fbposts}
            onChange={onChange}
          />
        </Form.Group>

        <Button variant="light" type="submit">
          Tallenna
        </Button>
      </Form>
    </div>
  )
}

export default Asetukset
