import { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { Button, Spinner } from 'react-bootstrap'
import './OtaYhteytta.scss'
import hostname from './../../hostname'

const OtaYhteytta = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const history = useHistory()
  const initialState = {
    name: '',
    phone: '',
    email: '',
    address: '',
    zipandtown: '',
    message: '',
  }

  //Spinner hook
  const [submitPending, setSubmitPending] = useState(false)

  const [form, setForm] = useState(initialState)
  const { register, handleSubmit, errors } = useForm()
  const onSubmit = async () => {
    setSubmitPending(true)
    try {
      const res = await axios.post(`${hostname}/send`, form)
      if (res.status === 200) {
        setSubmitPending(false)
        setForm(initialState)
        alert('Kiitos yhteydenotosta! Olemme teihin yhteydessä mahdollisimman pian.')
        window.scrollTo(0, 0)
        history.push('/')
      }
    } catch (err) {
      console.error(err)
    }
  }

  const onChange = (e) => setForm({ ...form, [e.target.name]: e.target.value })

  return (
    <div className='OtaYhteytta animate__animated animate__fadeIn'>
      <Helmet>
        <title>Laatu LKV | Ota yhteyttä | Kiinteistönvälitys Hämeenlinna. Kiinteä ja edullinen välityspalkkio. Hyvä ja kokenut kiinteistönvälittäjä. Asunnon myynti kodistasi välittäen.</title>
      </Helmet>
      <h1 className='customSubTitle' style={{ backgroundColor: '#fecbb8', padding: '16px 16px 12px 12px' }}>
        Ota yhteyttä
      </h1>
      <p>Ota yhteyttä rohkeasti, niin sovitaan aika maksuttomalle arviokäynnille ja kerromme lisää palveluistamme!</p>
      <p>Tähdellä (*) merkityt kentät ovat pakollisia.</p>

      <form
        id='contactForm'
        method='POST'
        // action="send"
        onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor='name'>Nimi *</label>
        <input name='name' onChange={onChange} ref={register({ required: true, minLength: 3 })} />
        {errors.name && <p id='error'>Nimi on pakollinen tieto.</p>}

        <label htmlFor='phone'>Puhelinnumero *</label>
        <input name='phone' onChange={onChange} ref={register({ required: true, maxLength: 13 })} />
        {errors.phone && <p id='error'>Puhelinnumero on pakollinen tieto.</p>}

        <label htmlFor='email'>Sähköpostiosoite *</label>
        <input name='email' onChange={onChange} ref={register({ required: true, minLength: 5 })} />
        {errors.email && <p id='error'>Sähköposti on pakollinen tieto.</p>}

        <label htmlFor='address'>Katuosoite</label>
        <input name='address' onChange={onChange} ref={register({ maxLength: 80 })} />

        <label htmlFor='zipandtown'>Postinumero ja -paikka</label>
        <input name='zipandtown' onChange={onChange} ref={register({ maxLength: 50 })} />

        <label htmlFor='message'>Viesti</label>
        <textarea name='message' onChange={onChange} rows='8' ref={register({ maxLength: 9999 })} />

        <Button variant='laatulkv' type='submit' disabled={submitPending}>
          {submitPending ? <Spinner animation='border' size='sm' variant='dark' /> : 'Lähetä'}
        </Button>
      </form>

      <p>Uusi tietosuoja-asetus tuli voimaan 25.5.2018.</p>
      <p>
        Lue tästä{' '}
        <strong>
          <a target='_blank' rel='noopener noreferrer' href={`https://laatulkv.fi/tietosuojaseloste`} alt=''>
            välitysliike Hämeen Laatu LKV tietosuojaseloste.
          </a>
        </strong>
      </p>

      <h2 className="customSubTitle">Vuokra-asuntohakemus</h2>
      <Link to='/vuokra-asuntohakemus' style={{ width: 'fit-content', margin: '0 auto' }}>
        <Button variant='laatulkv' style={{ padding: '10px 15px' }}>
          Avaa vuokra-asuntohakemus
        </Button>
      </Link>
    </div>
  )
}

export default OtaYhteytta
