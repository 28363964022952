import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { actions } from '../../redux/actions'
import './Kirjaudu.scss'

const Kirjaudu = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state) => state.general.isAuthenticated)

  const [appState, setAppState] = useState({
    email: '',
    password: '',
  })
  const { email, password } = appState

  const onChange = (e) =>
    setAppState({ ...appState, [e.target.name]: e.target.value })

  const onSubmit = (e) => {
    e.preventDefault()
    if (email === 'laatulkv-admin' && password === 'nPx2h7Aq9k') {
      const token = 'mo32zf0jnFS030gm3s8BqDl5meCi2p'
      localStorage.setItem('laatulkv-token', token)
      dispatch(actions.checkAuth())
    } else {
      window.alert('Väärä tunnus tai salasana.')
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/asetukset')
    }
    // eslint-disable-next-line
  }, [isAuthenticated])

  return (
    <div className="Kirjaudu animate__animated animate__fadeIn">
      <h1 className="customSubTitle">Kirjaudu</h1>
      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>Tunnus</Form.Label>
          <Form.Control
            name="email"
            type="text"
            placeholder="..."
            onChange={onChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Salasana</Form.Label>
          <Form.Control
            name="password"
            type="password"
            placeholder="..."
            onChange={onChange}
          />
        </Form.Group>

        <Button variant="light" type="submit">
          Kirjaudu
        </Button>
      </Form>
    </div>
  )
}

export default Kirjaudu
