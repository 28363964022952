import './Footer.scss'

import { Container, Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import kvkl from '../../images/KVKL-Hyva-valitystapa-merkki-FIN-Blanko-RGB.png'
import skvl from '../../images/skvl-2.png'
import yrittajaNaiset from '../../images/yrittajanaiset.jpeg'

import fb from '../../images/facebook-brands.svg'
import instagram from '../../images/instagram-brands.svg'

const Footer = () => {
  const loading = useSelector((state) => state.general.loading)
  const location = useLocation()
  return (
    <div
      className='Footer'
      hidden={
        (loading && location.pathname === '/') ||
        (loading && location.pathname === '') ||
        location.pathname === '/palaute' ||
        location.pathname === '/palaute/' ||
        location.pathname === '/palaute-ostin-kodin' ||
        location.pathname === '/palaute-ostin-kodin/' ||
        location.pathname === '/palaute-myin-kodin' ||
        location.pathname === '/palaute-myin-kodin/'
      }>
      <Container className='footerContainer'>
        <Row>
          <div>
            <a target='_blank' rel='noopener noreferrer' href='https://kiinteistonvalitysala.fi/'>
              <img src={kvkl} alt='Kiinteistönvälitysalan Keskusliitto' width='150' />
            </a>
          </div>
          <div>
            <a target='_blank' rel='noopener noreferrer' href='https://skvl.fi'>
              <img src={skvl} alt='SKVL Jäsen' width='150' />
            </a>
          </div>
          {/* <div>
            <img src={bisnode} alt='AAA Korkein luottoluokitus - Bisnode' width='150' />
          </div> */}
          <div>
            <a target='_blank' rel='noopener noreferrer' href='https://hameenlinnan.yrittajanaiset.fi/'>
              <img src={yrittajaNaiset} alt='Yrittäjänaiset' width='150' />
            </a>
          </div>
        </Row>
        <Row id='secondRow'>
          <Col>
            <h4 className='customSubTitle'>Yhteystiedot</h4>
            <div className='contactInfo'>
              <p>Hämeen Laatu LKV Oy</p>
              <p>Arvi Kariston katu 13,</p>
              <p>13100 Hämeenlinna</p>
              <p>y-tunnus 2838002-5</p>
              <p>
                p. 050 452 5254 | <a href='mailto:piia@laatulkv.fi'>piia@laatulkv.fi</a>
              </p>
            </div>
          </Col>
          <Col>
            <h4 className='customSubTitle'>Sosiaalinen Media</h4>
            <div className='socialLinks'>
              <div>
                <img src={fb} alt='Facebook logo' />
                <a target='_blank' rel='noopener noreferrer' href='https://www.facebook.com/'>
                  facebook.com/laatulkv
                </a>
              </div>
              <div>
                <img src={instagram} alt='Instagram logo' />
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://www.instagram.com/?hl=fi'>
                  @laatulkv
                </a>
              </div>
            </div>
            <div className='contactInfo'>
              <p>Verkkolaskuosoite: 003728380025</p>
              <p>Operaattori: Maventa (003721291126)</p>
            </div>
          </Col>
        </Row>
        {/* <Row>
          <img id="laatulkvLogo" src={logo} alt="Laatu LKV" height="58" />
        </Row> */}
        <Row>
          <p>Copyright © {new Date().getFullYear()} - Hämeen Laatu LKV Oy</p>
        </Row>
      </Container>
    </div>
  )
}

export default Footer
