import './Hinnasto.scss'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import hinnasto from '../../images/Hinnasto-iso-päiv-10_2020.png'

const Hinnasto = () => {
  const loading = useSelector((state) => state.general.loading)

  return (
    <div className="Hinnasto" hidden={loading}>
      <div className="HinnastoColText">
        <Link className="linkTitle" to="/palvelut">
          <h2 className="customSubTitle">Kiinteät välityspalkkiot</h2>
        </Link>
        <p>
          Kiinteät välityspalkkiomme tuovat selkeyttä asunnon myynti- ja
          vuokrausprosessiisi. Arvonlisäveron 24 % sisältävät palkkiomme
          perustuvat asunto-osakkeen huonelukuun, ja kiinteistöistä on niiden
          koosta riippumatta oma kiinteä palkkionsa.
        </p>
        <img src={hinnasto} alt="Hinnasto" />
      </div>
    </div>
  )
}

export default Hinnasto
