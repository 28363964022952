import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Esittely.scss'

import Info from '../Info/Info'
import piiaMika from '../../images/mika-ja-piia.jpg'

import { useSelector } from 'react-redux'

const Esittely = () => {
  const loading = useSelector((state) => state.general.loading)
  const sold = useSelector((state) => state.general.sold)
  const [infoComponent, setInfoComponent] = useState(undefined)

  useEffect(() => {
    if (sold) {
      setInfoComponent(
        <Info textOne={'Olemme välittäneet jo yli'} textTwo={'kotia.'} value={sold} />
      )
    }
  }, [sold])

  return (
    <div className='Esittely' hidden={loading}>
      <div className='esittelyColText'>
        <Link className='linkTitle' to='/laatulkv'>
          <h2 className='customSubTitle'>Hämeen Laatu LKV</h2>
        </Link>
        <p>
          Laatu LKV on vuonna 2017 perustettu kiinteistönvälitysyritys, joka tarjoaa laadukasta ja
          luotettavaa kiinteistönvälityspalvelua Hämeenlinnassa ja sen lähialueella. Kiinteät
          välityspalkkiomme tuovat selkeyttä asunnon myynti- ja vuokrausprosessiisi.
        </p>
        <p>
          Meille on etuoikeus saada olla tukenasi yhdessä elämäsi isoimmista päätöksistä
          positiivisella ja periksiantamattomalla otteellamme. Tunnemme jokaisen meillä myynnissä
          olevan kodin kuin omamme ja teemme asiakaskokemuksestasi liukuhihnafiiliksen sijaan
          parhaan mahdollisen.
        </p>
        <p>
          Laatu LKV kuuluu{' '}
          <a href='https://skvl.fi/' target='_blank' rel='noopener noreferrer'>
            Suomen Kiinteistönvälitysliittoon (SKVL)
          </a>
          , joten käytössämme on alan viimeisin tieto yhdistettynä hyviin yhteistyökumppaneihin.
          Yrittäjämme Piia kuuluu{' '}
          <a href='https://www.yrittajanaiset.fi/' target='_blank' rel='noopener noreferrer'>
            Suomen yrittäjänaisiin
          </a>{' '}
          ja tuemme paikallista yrittäjyyttä. Teemme yhteistyötä{' '}
          <a
            href='https://www.poppankki.fi/lammin-osuuspankki'
            target='_blank'
            rel='noopener noreferrer'>
            POP-pankin
          </a>{' '}
          Hämeenlinnan konttorin kanssa ja tehokkaan henkilökohtaisen palvelun lisäksi asiakkaamme
          saavat etuja heidän palveluistaan. Lisäksi olemme verkostoituneet paikallisten
          pienyrittäjien kanssa tarjotaksemme asiakkaillemme etuhintaisia palveluilta muihinkin
          asunnon ja paikkakunnan vaihtoon liittyviin palveluihin.
        </p>
        {infoComponent ? infoComponent : null}
      </div>

      <div className='esittelyColImage'>
        <img src={piiaMika} alt='Piia Heikkilä ja Mika Lehtonen' />
      </div>
    </div>
  )
}

export default Esittely
