import './LandingMobile.scss'
import { useSelector } from 'react-redux'
// import arrowIcon from '../../images/long-arrow-alt-down-solid.svg'

const LandingMobile = () => {
  const loading = useSelector((state) => state.general.loading)

  return (
    <div
      className="LandingMobile animate__animated animate__fadeIn"
      id="LandingMobile"
      hidden={loading}
    >
      <div className="animatedLogo">
        <div className="animate__animated animate__fadeIn animate__delay-1s">
          <h1>Laatu LKV</h1>
        </div>
        <h2 className="animate__animated animate__fadeIn animate__delay-2s">
          KODISTASI VÄLITTÄEN
        </h2>
        {/* <img
          src={arrowIcon}
          alt=""
          className="animate__animated animate__bounce animate__delay-2s"
        /> */}
      </div>
    </div>
  )
}

export default LandingMobile
