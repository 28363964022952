import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import './Vuokrahakemus.scss'
import VuokraAsuntoHakemusUusi from './VuokraAsuntoHakemusUusi'

const Vuokrahakemus = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='Vuokrahakemus animate__animated animate__fadeIn'>
      <Helmet>
        <title>Laatu LKV | Vuokra-asuntohakemus | Kiinteistönvälitys Hämeenlinna. Kiinteä ja edullinen välityspalkkio. Hyvä ja kokenut kiinteistönvälittäjä. Asunnon myynti kodistasi välittäen.</title>
      </Helmet>
      <h1
        className='customSubTitle'
        style={{ backgroundColor: '#fecbb8', padding: '16px 16px 8px 12px' }}>
        Vuokra-asuntohakemus
      </h1>
      <VuokraAsuntoHakemusUusi/>
    </div>
  )
}

export default Vuokrahakemus
