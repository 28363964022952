import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Landing from '../../components/Landing/Landing'
import LandingMobile from '../../components/LandingMobile/LandingMobile'
import Esittely from '../../components/Esittely/Esittely'
import EsittelyVideo from '../../components/EsittelyVideo/EsittelyVideo'
import Piia from '../../components/Piia/Piia'
import Mika from '../../components/Mika/Mika'
import Uusimmat from '../../components/Uusimmat/Uusimmat'
import Hinnasto from '../../components/Hinnasto/Hinnasto'
import FbFeed from '../../components/FbFeed/FbFeed'
import ScrollAnimation from 'react-animate-on-scroll'

const Etusivu = (props) => {
  const { screenWidth } = props

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Laatu LKV | Kiinteistönvälitys Hämeenlinna. Kiinteä ja edullinen välityspalkkio. Hyvä ja kokenut kiinteistönvälittäjä. Asunnon myynti kodistasi välittäen.</title>
      </Helmet>
      {screenWidth > 1024 ? <Landing /> : <LandingMobile />}
      <ScrollAnimation
        animateIn="animate__animated animate__fadeIn"
        animateOnce={true}
        animatePreScroll={false}
        offset={450}
      >
        <Esittely />
      </ScrollAnimation>

      <ScrollAnimation
        animateIn="animate__animated animate__fadeIn"
        animateOnce={true}
        animatePreScroll={false}
        offset={450}
      >
        <EsittelyVideo />
      </ScrollAnimation>

      <ScrollAnimation
        animateIn="animate__animated animate__fadeIn"
        animateOnce={true}
        animatePreScroll={false}
        offset={450}
      >
        <Piia />
      </ScrollAnimation>

      <ScrollAnimation
        animateIn="animate__animated animate__fadeIn"
        animateOnce={true}
        animatePreScroll={false}
        offset={450}
      >
        <Mika />
      </ScrollAnimation>

      <ScrollAnimation
        animateIn="animate__animated animate__fadeIn"
        animateOnce={true}
        animatePreScroll={false}
        offset={450}
      >
        <Hinnasto />
      </ScrollAnimation>

      <ScrollAnimation
        animateIn="animate__animated animate__fadeIn"
        animateOnce={true}
        animatePreScroll={false}
        offset={450}
      >
        <Uusimmat />
      </ScrollAnimation>

      <ScrollAnimation
        animateIn="animate__animated animate__fadeIn"
        animateOnce={true}
        animatePreScroll={false}
        offset={450}
      >
        <FbFeed />
      </ScrollAnimation>
    </>
  )
}

export default Etusivu
