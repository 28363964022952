// React
import React, { useState } from 'react'

// Bootstrap
import Button from 'react-bootstrap/Button'

// Styling
import chevronUp from '../../images/chevron-arrow-up.png'
import './ScrollToTop.scss'

const ScrollToTop = () => {

  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400){
      setShowScroll(false)
    }
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  window.addEventListener('scroll', checkScrollTop)

  return (
        <Button className="scrollToTop" variant="dark" onClick={scrollTop} style={{display: showScroll ? 'flex' : 'none'}} ><img src={chevronUp} alt="Nuoli ylös" /></Button>
  )
}

export default ScrollToTop