import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Button } from 'react-bootstrap'
import logo from '../../../images/laatulkv_logo_iso.png'
import './Palaute.scss'

const Palaute = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const history = useHistory()

  const myinKodin = () => history.push('/palaute-myin-kodin')
  const ostinKodin = () => history.push('/palaute-ostin-kodin')

  return (
    <div className="Asiakaspalaute-bg">
      <div className="Asiakaspalaute animate__animated animate__fadeIn">
        <Helmet>
          <title>Laatu LKV | Asiakaspalaute | Kiinteistönvälitys Hämeenlinna. Kiinteä ja edullinen välityspalkkio. Hyvä ja kokenut kiinteistönvälittäjä. Asunnon myynti kodistasi välittäen.</title>
        </Helmet>

        <img src={logo} alt="Laatu LKV" />
        <h1
          className="customSubTitle"
          style={{ backgroundColor: '#fff', padding: '16px 16px 12px 12px' }}
        >
          Tervetuloa antamaan palautetta työstämme!
        </h1>
        <p id="ohje-teksti">Jatka valitsemalla seuraavista vaihtoehdoista:</p>

        <div id="palaute-painikkeet">
          <Button variant="laatulkv" type="submit" onClick={myinKodin}>
            Myin kodin
          </Button>
          <Button variant="laatulkv" type="submit" onClick={ostinKodin}>
            Ostin kodin
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Palaute
